import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";

export const INITIAL_STATE = {
    showLocationPicker: false
};

export const toggleLocationPicker = (state = INITIAL_STATE, {status}) => {
    return { ...state, showLocationPicker: status !== undefined ? status : !state.showLocationPicker};
};

export const HANDLERS = {
    [Types.TOGGLE_LOCATION_PICKER]: toggleLocationPicker
};

export default createReducer(INITIAL_STATE, HANDLERS);
