import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";

export const INITIAL_STATE = {
    updatedAt: null,
    monthlyIncome: "",
    monthlyEmi: "",
    sourcePage: null,
    source: null,
    maxCarEmi: null,
    maxCarPrice: null,
    carAvailable: null
};

export const updateLoanEligibility = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        ...data
    };
};

export const HANDLERS = {
    [Types.UPDATE_LOAN_ELIGIBILITY]: updateLoanEligibility
};

export default createReducer(INITIAL_STATE, HANDLERS);
