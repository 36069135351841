import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";
import { OFFER_TYPE, FINANCE_SCREEN  } from "../../../constants/ae/checkout-constants";

export const INITIAL_STATE = {
    financeSelectedData: {
        selectedLoanTenure: "",
        selectedCreditScore: "",
        selectedDownPaymentValue: "",
        selectedMonthlyEmiValue: ""
    },
    financeUpdatedData: {
        creditScore: 0,
        downPayment: 0,
        emi: 0,
        tenure: 0,
        chargeList: []
    },
    financeData: {
        creditScore: [],
        loanTenure: [],
        minEmiValue: "",
        maxEmiValue: "",
        minDownpaymentValue: "",
        maxDownpaymentValue: "",
        paymentStepSize: "",
        emiStepSize: "",
        defaultLoanTenureValue: 12,
        defaultRoi: 13,
        defaultMonthlyEmiValue: "",
        defaultDownPaymentValue: "",
        defaultCreditScoreValue: "",
        offerType: OFFER_TYPE.User,
        activeTab: FINANCE_SCREEN.FINANCE
    }
};

export const setFinancingTab = (state = INITIAL_STATE, { financingTab }) => {
    return {
        ...state,
        financeData: { ...state.financeData, activeTab: financingTab}
    };
};

export const setDownPaymentValue = (state = INITIAL_STATE, { downPaymentValue }) => {
    return {
        ...state,
        financeSelectedData: {
            ...state.financeSelectedData,
            selectedDownPaymentValue: downPaymentValue
        }
    };
};

export const setMonthlyEmiValue = (state = INITIAL_STATE, { monthlyEmiValue }) => {
    return {
        ...state,
        financeSelectedData: {
            ...state.financeSelectedData,
            selectedMonthlyEmiValue: monthlyEmiValue
        }
    };
};

export const setFinanceSelected = (state = INITIAL_STATE, { financeSelected }) => {
    return {
        ...state,
        financeSelected
    };
};

export const setSelectedLoanTenure = (state = INITIAL_STATE, { loanTenure }) => {
    return {
        ...state,
        financeSelectedData: {
            ...state.financeSelectedData,
            selectedLoanTenure: loanTenure
        }
    };
};

export const setCreditScoreValue = (state = INITIAL_STATE, { creditScoreValue }) => {
    return {
        ...state,
        financeSelectedData: {
            ...state.financeSelectedData,
            selectedCreditScore: creditScoreValue
        }
    };
};

export const setMinMaxDownPaymentValue = (state = INITIAL_STATE, { minDownpaymentValue, maxDownpaymentValue }) => {
    return {
        ...state,
        financeData: {
            ...state.financeData,
            minDownpaymentValue,
            maxDownpaymentValue
        }
    };
};

export const setFinanceChargeList = (state = INITIAL_STATE, { financeChargeList }) => {
    return {
        ...state,
        financeChargeList
    };
};

export const setChargeDetailsList = (state = INITIAL_STATE, { chargeDetailsList }) => {
    return {
        ...state,
        chargeDetailsList
    };
};

export const setMinMaxEmiValue = (state = INITIAL_STATE, { minEmiValue, maxEmiValue }) => {
    return {
        ...state,
        financeData: {
            ...state.financeData,
            minEmiValue,
            maxEmiValue
        }
    };
};

export const setFinanceData = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        financeData: {
            ...state.financeData,
            creditScore: data.creditScoreList,
            loanTenure: data.loanTenureList,
            defaultLoanTenureValue: data.defaultLoanTenureValue,
            defaultRoi: data.defaultRoi,
            defaultMonthlyEmiValue: data.defaultMonthlyEmiValue,
            defaultDownPaymentValue: data.defaultDownPaymentValue,
            minDownpaymentValue: data.minDownpaymentValue,
            maxDownpaymentValue: data.maxDownpaymentValue,
            minEmiValue: data.minEmiValue,
            maxEmiValue: data.maxEmiValue,
            paymentStepSize: data.paymentStepSize,
            emiStepSize: data.emiStepSize,
            defaultCreditScoreValue: data.defaultCreditScoreValue
        }
    };
};

export const setFinanceUpdatedData = (state = INITIAL_STATE, {
    creditScore,
    downPayment,
    emi,
    tenure,
    chargeList
}) => {
    return {
        ...state,
        financeUpdatedData: {
            ...state.financeUpdatedData,
            ...(creditScore && {creditScore}),
            ...(downPayment && {downPayment}),
            ...(emi && {emi}),
            ...(tenure && {tenure}),
            ...(chargeList && {chargeList})
        }
    };
};

export const setSelectedFinancingData = (state = INITIAL_STATE, { loanTenure }) => {
    return {
        ...state,
        financeSelectedData: {
            ...state.financeSelectedData,
            selectedLoanTenure: loanTenure
        }
    };
};

export const HANDLERS = {
    [Types.SET_SELECTED_FINANCING_DATA]: setSelectedFinancingData,
    [Types.SET_FINANCE_DATA]: setFinanceData,
    [Types.SET_CREDIT_SCORE_VALUE]: setCreditScoreValue,
    [Types.SET_DOWNPAYMENT_VALUE]: setDownPaymentValue,
    [Types.SET_MONTHLY_EMI_VALUE]: setMonthlyEmiValue,
    [Types.SET_LOAN_TENURE_VALUE]: setSelectedLoanTenure,
    [Types.SET_FINANCING_TAB]: setFinancingTab,
    [Types.SET_MIN_MAX_DOWNPAYMENT_VALUE]: setMinMaxDownPaymentValue,
    [Types.SET_MIN_MAX_EMI_VALUE]: setMinMaxEmiValue,
    [Types.SET_FINANCE_SELECTED]: setFinanceSelected,
    [Types.SET_FINANCE_UPDATED_DATA]: setFinanceUpdatedData
};

export default createReducer(INITIAL_STATE, HANDLERS);
