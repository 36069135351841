import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    QUACK
    SET_RETURN_VISIBLE
    SET_TOP_TICKET
    SET_ALL_TICKETS
`,
    {
        prefix: "postbookingdeliveredcard/"
    }
);
