import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";
import { setUserPersonalDetail } from "./actions";
import {
    CHECKOUT_STEP_KEY,
    CHECKOUT_STEP_CONTROL_TAB,
    OFFER_TYPE,
    FINANCE_SCREEN
} from "../../../constants/ae/checkout-constants";
import { DELIVERY_TYPE } from "../test-drive-locations/constant";
import { BOOKING_SCREEN_TYPE } from "../checkout-confirm-booking/constant";

const getStepindex = (currentStep, tabs = CHECKOUT_STEP_CONTROL_TAB) => {
    const currentTab = tabs.filter(tab =>
        currentStep.toLowerCase().includes(tab.name.toLowerCase())
    );
    return currentTab[0].index - 1;
};

const ORDER_INITIAL_STATE = {
    bookingAmount: 1,
    bookingVariables: {
        nexBookingAmount: 1,
        exBookingAmount: 1,
        bookingType: ""
    }, //TOD
    bookingConfirmDate: null,
    bookingExpireDate: null,
    bookingType: null,
    chargeDetails: null,
    checkoutState: null,
    createdDate: null,
    currency: "AED",
    deliveryAddress: null,
    deliveryMode: null,
    financeOpted: true,
    financeTerms: null,
    id: "",
    orderId: null,
    pickupStoreId: null,
    pickupTime: null,
    status: null,
    multipleBookingCase: false,
    storeId: "",
    totalAmount: 0,
    userId: "",
    vehiclePrice: 0,
    vehicleType: "car",
    fasterCheckout: null,
    isRefundableHomeBc: null,
    latestOffer: {}
};

export const INITIAL_STATE = {
    userPersonalDetail: {
        name: "",
        mobile: "",
        email: "",
        otp: "",
        accessToken: ""
    },
    financeUpdatedData: {
        creditScore: 0,
        downPayment: 0,
        emi: 0,
        tenure: 0,
        chargeList: []
    },
    order: { ...ORDER_INITIAL_STATE },
    financeData: {
        creditScore: [],
        loanTenure: [],
        minEmiValue: "",
        maxEmiValue: "",
        minDownpaymentValue: "",
        maxDownpaymentValue: "",
        paymentStepSize: "",
        emiStepSize: "",
        defaultLoanTenureValue: 12,
        defaultRoi: 3.5,
        defaultMonthlyEmiValue: "",
        defaultDownPaymentValue: "",
        defaultCreditScoreValue: "",
        offerType: OFFER_TYPE.User,
        activeTab: FINANCE_SCREEN.FINANCE,
        activeScreen: FINANCE_SCREEN.FINANCE_MODE
    },
    chargeDetailsList: [],
    vehiclePrice: 0,
    financeSelected: false,
    orderStatus: "",
    orderId: 0,
    warrantyCharge: 0,
    insuranceChange: 0,
    rcHandling: 0,
    currentStep: CHECKOUT_STEP_KEY.Process,
    currentStepIndex: getStepindex(CHECKOUT_STEP_KEY.Process),
    currentTabIndex: getStepindex(CHECKOUT_STEP_KEY.Process),
    prevTabIndex: 0,
    showPersonalDetailSuccessMessage: false,
    orderCreatedDatetime: new Date().toISOString(),
    deliveryMode: DELIVERY_TYPE.HOME_DELIVERY,
    processingFee: 0,
    stampDuty: 0,
    chm: 0,
    deliveryInfo: {
        pincodeDeliveryData: { complimentaryBenefits: [] },
        deliveryInfoError: null,
        deliveryInfoFetched: false,
        appliedDeliveryPincode: "",
        selectedPickupLocationIndex: 0,
        selectedHomeDeliveryIndex: 0,
        activeTab: DELIVERY_TYPE.HOME_DELIVERY,
        updateDeliveryError: null
    },
    confirmBookingInfo: {
        activeScreen: BOOKING_SCREEN_TYPE.ORDER_SUMMARY,
        enablePaymentFailedScreen: false
    },
    createOrderError: null,
    getOrderDetailError: null,
    isOrderDetailLoading: true,
    tradeInChoice: null,
    testDriveLocations: [],
    selectedTestDriveMode: null,
    testDriveChange: null,
    isOrderLoading: false,
    isOrderFetched: false,
    acceptedOffer: {
        isLoading: false,
        data: null,
        hasError: false
    }
};

const getUpdatedFinanceTerms = (financeTerms, currentFinanceTerms) => {
    if (!financeTerms && !currentFinanceTerms) {
        return null;
    }
    return {
        ...currentFinanceTerms,
        chargeDetails: financeTerms.chargeDetails,
        downPayment: financeTerms.downPayment,
        emiAmount: financeTerms.emiAmount,
        emiType: financeTerms.emiType,
        interestRate: financeTerms.interestRate,
        loanAmount: financeTerms.loanAmount,
        offerType: financeTerms.offerType,
        tenure: financeTerms.tenure
    };
};

const getDeliveryAddress = (deliveryAddress, currentDeliveryAddress) => {
    if (!deliveryAddress && !currentDeliveryAddress) {
        return null;
    }
    return {
        ...currentDeliveryAddress,
        addressLine1: deliveryAddress.addressLine1,
        addressLine2: deliveryAddress.addressLine2,
        landmark: deliveryAddress.landmark,
        city: deliveryAddress.city,
        state: deliveryAddress.state,
        pincode: deliveryAddress.pincode,
        customerName: deliveryAddress.customerName,
        customerPhoneNumber: deliveryAddress.customerPhoneNumber,
        latitude: deliveryAddress.latitude,
        longitude: deliveryAddress.longitude,
        mapLink: deliveryAddress.mapLink
    };
};

export const setOrder = (state = INITIAL_STATE, {
    bookingAmount,
    bookingVariables,
    bookingConfirmDate,
    bookingExpireDate,
    bookingType,
    chargeDetails,
    country,
    checkoutState,
    createdDate,
    currency,
    deliveryAddress,
    deliveryMode,
    financeOpted,
    financeTerms,
    id,
    orderId,
    multipleBookingCase,
    pickupStoreId,
    pickupTime,
    status,
    storeId,
    totalAmount,
    userId,
    vehiclePrice,
    vehicleType,
    claimedCoupons,
    fasterCheckout,
    hubToHomeChangeAttempted,
    isRefundableHomeBc,
    latestOffer
}) => {
    return {
        ...state,
        isOrderDetailLoading: false,
        order: {
            ...state.order,
            bookingAmount,
            bookingVariables,
            bookingConfirmDate,
            bookingExpireDate,
            bookingType,
            chargeDetails,
            checkoutState,
            country,
            createdDate,
            currency,
            deliveryAddress: getDeliveryAddress(deliveryAddress, state.deliveryAddress),
            deliveryMode,
            financeOpted,
            financeTerms: getUpdatedFinanceTerms(financeTerms, state.financeTerms),
            id,
            multipleBookingCase,
            orderId,
            pickupStoreId,
            pickupTime,
            status,
            storeId,
            totalAmount,
            userId,
            vehiclePrice,
            vehicleType,
            claimedCoupons,
            fasterCheckout,
            hubToHomeChangeAttempted,
            isRefundableHomeBc,
            latestOffer
        }
    };
};

export const setDeliveryMode = (state = INITIAL_STATE, { deliveryMode }) => {
    return {
        ...state,
        deliveryMode
    };
};

export const setOrderId = (state = INITIAL_STATE, { orderId }) => {
    return {
        ...state,
        orderId
    };
};

export const setFinancingTab = (state = INITIAL_STATE, { financingTab }) => {
    return {
        ...state,
        financeData: { ...state.financeData, activeTab: financingTab }
    };
};

export const setFinancingScreen = (state = INITIAL_STATE, { activeScreen }) => {
    return {
        ...state,
        financeData: { ...state.financeData, activeScreen }
    };
};

export const setOrderCreatedDateTime = (state = INITIAL_STATE, { orderCreatedDatetime }) => {
    return {
        ...state,
        orderCreatedDatetime
    };
};

export const setFinanceSelected = (state = INITIAL_STATE, { financeSelected }) => {
    return {
        ...state,
        financeSelected
    };
};

export const setMinMaxDownPaymentValue = (state = INITIAL_STATE, { minDownpaymentValue, maxDownpaymentValue }) => {
    return {
        ...state,
        financeData: {
            ...state.financeData,
            minDownpaymentValue,
            maxDownpaymentValue
        }
    };
};

export const setChargeDetailsList = (state = INITIAL_STATE, { chargeDetailsList }) => {
    return {
        ...state,
        chargeDetailsList
    };
};

export const setMinMaxEmiValue = (state = INITIAL_STATE, { minEmiValue, maxEmiValue }) => {
    return {
        ...state,
        financeData: {
            ...state.financeData,
            minEmiValue,
            maxEmiValue
        }
    };
};

export const setFinanceData = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        financeData: {
            ...state.financeData,
            creditScore: data.creditScoreList,
            loanTenure: data.loanTenureList,
            defaultLoanTenureValue: data.defaultLoanTenureValue,
            defaultRoi: data.defaultRoi,
            defaultMonthlyEmiValue: data.defaultMonthlyEmiValue,
            defaultDownPaymentValue: data.defaultDownPaymentValue,
            minDownpaymentValue: data.minDownpaymentValue,
            maxDownpaymentValue: data.maxDownpaymentValue,
            minEmiValue: data.minEmiValue,
            maxEmiValue: data.maxEmiValue,
            paymentStepSize: data.paymentStepSize,
            emiStepSize: data.emiStepSize,
            defaultCreditScoreValue: data.defaultCreditScoreValue,
            defaultLoanAmount: data.defaultLoanAmount,
            maxLoanAmount: data.maxLoanAmount,
            minLoanAmount: data.minLoanAmount
        }
    };
};

export const setPersonalDetailSucessMessage = (state = INITIAL_STATE, { successMessage }) => {
    return { ...state, showPersonalDetailSuccessMessage: successMessage };
};

export const setStep = (state = INITIAL_STATE, { currentStep, tabs }) => {
    const prevTabIndex = state.currentTabIndex;
    const currentTabIndex = getStepindex(currentStep, tabs);
    const currentStepIndex = getStepindex(currentStep, tabs);
    return { ...state, currentStep, currentStepIndex, currentTabIndex, prevTabIndex };
};

export const setFinanceUpdatedData = (state = INITIAL_STATE, {
    creditScore,
    downPayment,
    emi,
    tenure,
    chargeList
}) => {
    return {
        ...state,
        financeUpdatedData: {
            ...state.financeUpdatedData,
            ...(creditScore && { creditScore }),
            ...(downPayment && { downPayment }),
            ...(emi && { emi }),
            ...(tenure && { tenure }),
            ...(chargeList && { chargeList })
        }
    };
};

export const setDeliveryData = (state = INITIAL_STATE, { data }) => {
    return {
        ...state, deliveryInfo: {
            ...state.deliveryInfo,
            ...data
        }
    };
};

export const updateDeliveryModeSuccess = (state = INITIAL_STATE, { data }) => {
    return setOrder(state, data);
};

export const updateDeliveryModeFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        deliveryInfo: {
            ...state.deliveryInfo,
            updateDeliveryError: error
        }
    };
};

export const updateBookingTypeSuccess = (state = INITIAL_STATE, { data }) => {
    return setOrder(state, data);
};

export const setConfirmBookingData = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        confirmBookingInfo: {
            ...state.confirmBookingInfo,
            ...data
        }
    };
};

export const createOrderSuccess = (state = INITIAL_STATE, { data }) => {
    return setOrder(state, data);
};

export const createOrderFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        createOrderError: error
    };
};

export const getOrderDetailInit = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        isOrderDetailLoading: data
    };
};

export const getOrderDetailSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...setOrder(state, data),
        isOrderDetailLoading: false,
        getOrderDetailError: null
    };
};

export const getOrderDetailFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        order: { ...ORDER_INITIAL_STATE },
        isOrderDetailLoading: false,
        getOrderDetailError: error
    };
};

export const setTradeInChoice = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        tradeInChoice: data
    };
};

export const setTestDriveModes = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        ...data
    };
};

export const setSelectedDeliveryInfo = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        ...data
    };
};

export const setChangeTdLocation = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        testDriveChange: data
    };
};

// C2C Checkout : START

const submitCarOfferInit = (state = INITIAL_STATE, { withLoader = true }) => {
    return {
        ...state,
        isOrderLoading: withLoader,
        isError: false
    };
};

const submitCarOfferSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        isOrderLoading: false,
        isOrderFetched: true,
        isError: false,
        order: { ...data }
    };
};

const submitCarOfferFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        isOrderLoading: false,
        isOrderFetched: true,
        isError: true,
        order: { ...ORDER_INITIAL_STATE }
    };
};

const acceptCarOfferInit = (state = INITIAL_STATE) => {
    return {
        ...state,
        acceptedOffer: {
            isLoading: true,
            data: null,
            hasError: false
        }
    };
};

const acceptCarOfferSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        acceptedOffer: {
            isLoading: false,
            data,
            hasError: false
        },
        order: {
            ...state.order,
            latestOffer: data
        }
    };
};

const acceptCarOfferFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        acceptedOffer: {
            isLoading: false,
            data: null,
            hasError: true
        }
    };
};

// C2C Checkout : END

export const HANDLERS = {
    [Types.SETUSER_PERSONAL_DETAIL]: setUserPersonalDetail,
    [Types.SET_CURRENT_STEP]: setStep,
    [Types.SET_PERSONAL_DETAIL_MESSAGE_FORM_ACTIVE]: setPersonalDetailSucessMessage,
    [Types.SET_FINANCE_DATA]: setFinanceData,
    [Types.SET_DELIVERY_MODE]: setDeliveryMode,
    [Types.SET_FINANCING_TAB]: setFinancingTab,
    [Types.SET_MIN_MAX_DOWNPAYMENT_VALUE]: setMinMaxDownPaymentValue,
    [Types.SET_MIN_MAX_EMI_VALUE]: setMinMaxEmiValue,
    [Types.SET_FINANCE_SELECTED]: setFinanceSelected,
    [Types.SET_ORDER_ID]: setOrderId,
    [Types.SET_ORDER]: setOrder,
    [Types.SET_FINANCE_UPDATED_DATA]: setFinanceUpdatedData,
    [Types.SET_DELIVERY_DATA]: setDeliveryData,
    [Types.SET_FINANCE_SCREEN]: setFinancingScreen,
    [Types.UPDATE_DELIVERY_MODE_SUCCESS]: updateDeliveryModeSuccess,
    [Types.UPDATE_DELIVERY_MODE_FAILURE]: updateDeliveryModeFailure,
    [Types.UPDATE_BOOKING_TYPE_SUCCESS]: updateBookingTypeSuccess,
    [Types.SET_CONFIRM_BOOKING_DATA]: setConfirmBookingData,
    [Types.CREATE_ORDER_SUCCESS]: createOrderSuccess,
    [Types.CREATE_ORDER_FAILURE]: createOrderFailure,
    [Types.SET_CHARGE_LIST]: setChargeDetailsList,
    [Types.GET_ORDER_DETAIL_SUCCESS]: getOrderDetailSuccess,
    [Types.GET_ORDER_DETAIL_FAILURE]: getOrderDetailFailure,
    [Types.GET_ORDER_DETAIL_INIT]: getOrderDetailInit,
    [Types.TRADE_IN_CHOICE]: setTradeInChoice,
    [Types.SET_TEST_DRIVE_MODE]: setTestDriveModes,
    [Types.SET_SELECTED_DELIVERY_INFO]: setSelectedDeliveryInfo,
    [Types.SET_CHANGE_TD_LOCATION]: setChangeTdLocation,

    // C2C Checkout : START
    [Types.SUBMIT_CAR_OFFER_INIT]: submitCarOfferInit,
    [Types.SUBMIT_CAR_OFFER_SUCCESS]: submitCarOfferSuccess,
    [Types.SUBMIT_CAR_OFFER_FAILURE]: submitCarOfferFailure,

    [Types.ACCEPT_CAR_OFFER_INIT]: acceptCarOfferInit,
    [Types.ACCEPT_CAR_OFFER_SUCCESS]: acceptCarOfferSuccess,
    [Types.ACCEPT_CAR_OFFER_FAILURE]: acceptCarOfferFailure
    // C2C Checkout : END
};

export default createReducer(INITIAL_STATE, HANDLERS);
