import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";

export const INITIAL_STATE = {
    name: "",
    make: "",
    model: "",
    variant: "",
    year: null,
    appointmentId: null,
    dsOfferedPrice: 0,
    finalOfferedPrice: 0,
    hasPriceChangedAfterInspection: true,
    isLoading: false,
    status: "",
    priceAccepted: false,
    disputeMessages: [],
    reasonsList: null
};

export const fetchInspectionDetailsRequest = (state = INITIAL_STATE) => {
    return {
        ...state,
        isLoading: true
    };
};

export const fetchInspectionDetailsSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        ...data,
        isLoading: false
    };
};

export const fetchInspectionDetailsFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        isLoading: false,
        error
    };
};

export const updateInspectionEvaluationRequest = (state = INITIAL_STATE) => {
    return {
        ...state,
        isLoading: true
    };
};

export const updateInspectionEvaluationSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        ...data,
        isLoading: false
    };
};

export const updateInspectionEvaluationFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        isLoading: false,
        error
    };
};

export const updateInspectionEvaluationDisputes = (state = INITIAL_STATE, data) => {
    return {
        ...state,
        data
    };
};

export const submitInspectionDisputesRequest = (state = INITIAL_STATE) => {
    return {
        ...state,
        isLoading: true
    };
};

export const submitInspectionDisputesSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        ...data,
        isLoading: false
    };
};

export const submitInspectionDisputesFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        isLoading: false,
        error
    };
};

export const setFeedbackReasonsOptions = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        reasonsList: data
    };
};

export const HANDLERS = {
    [Types.FETCH_INSPECTION_REQUEST]: fetchInspectionDetailsRequest,
    [Types.FETCH_INSPECTION_SUCCESS]: fetchInspectionDetailsSuccess,
    [Types.FETCH_INSPECTION_FAILURE]: fetchInspectionDetailsFailure,
    [Types.UPDATE_INSPECTION_EVALUATION_REQUEST]: updateInspectionEvaluationRequest,
    [Types.UPDATE_INSPECTION_EVALUATION_SUCCESS]: updateInspectionEvaluationSuccess,
    [Types.UPDATE_INSPECTION_EVALUATION_FAILURE]: updateInspectionEvaluationFailure,
    [Types.UPDATE_INSPECTION_EVALUATION_DISPUTES]: updateInspectionEvaluationDisputes,
    [Types.SUBMIT_DISPUTES_TO_INSPECTION_REQUEST]: submitInspectionDisputesRequest,
    [Types.SUBMIT_DISPUTES_TO_INSPECTION_SUCCESS]: submitInspectionDisputesSuccess,
    [Types.SUBMIT_DISPUTES_TO_INSPECTION_FAILURE]: submitInspectionDisputesFailure,
    [Types.SET_FEEDBACK_REASONS_LIST]: setFeedbackReasonsOptions
};

export default createReducer(INITIAL_STATE, HANDLERS);

