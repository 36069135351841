import React, { memo } from "react";
import PropTypes from "prop-types";

// Styles
import styles from "./styles.css";

// Utilities
import { formatDate } from "../../../utils/helpers/get-day-from-date";
import { TICKET_STATUS } from "../../../constants/app-constants";

const ChatScreenTicketCard = memo(({
    ticketId,
    ticketType,
    isTicketOpen,
    createdDate
}) => {
    return (
        <div styleName={"styles.ticketCard"}>
            <div styleName={"styles.dFlex"}>
                <h3>{ticketType}</h3>
                <label styleName={isTicketOpen ? "styles.green" : "styles.gray"}>
                    {isTicketOpen ? TICKET_STATUS.OPEN : TICKET_STATUS.CLOSED}
                </label>
            </div>
            <div styleName={"styles.dFlex"}>
                <p>Ticket ID: <span>#{ticketId}</span></p>
                <h4>{formatDate(createdDate, "DATE_MONTH_TIME")}</h4>
            </div>
        </div>
    );
});

ChatScreenTicketCard.propTypes = {
    isTicketOpen: PropTypes.bool,
    ticketType: PropTypes.string,
    ticketId: PropTypes.string,
    createdDate: PropTypes.string,
    ticketCurrentStatus: PropTypes.string
};

export default ChatScreenTicketCard;
