import { b2cDetailPageEvents } from "../../../tracking/msite/b2c-detail-page-events";

export const GALLERY_TAB_TYPE = {
    VIEW_360: "VIEW_360",
    PHOTOS: "PHOTOS",
    EXTERIOR: "EXTERIOR",
    INTERIOR: "INTERIOR",
    GALLERY: "GALLERY",
    IMPERFECTIONS: "IMPERFECTIONS",
    FEATURES: "FEATURES"
};

export const GA_IMAGE_MAP = {
    [GALLERY_TAB_TYPE.VIEW_360]: "spinGalleryND",
    [GALLERY_TAB_TYPE.EXTERIOR]: "extGalleryND",
    [GALLERY_TAB_TYPE.INTERIOR]: "intGalleryND",
    [GALLERY_TAB_TYPE.IMPERFECTIONS]: "impGalleryND"
};

export const HELLO_AR_GALLERY_TYPE = {
    VIEW_360: "VIEW_360",
    EXTERIOR: "Exterior",
    INTERIOR: "Interior",
    IMPERFECTIONS: "Imperfections"
};

export const STICKY_TABS_VARIANT = {
    OVERVIEW: "overview",
    FEATURES: "features",
    CAR_CONDITION: "carCondition",
    SERVICE_HISTORY: "serviceHistory",
    CAR_FINANCE: "carFinance",
    OUR_HAPPY_CUSTOMERS: "ourHappyCustomers",
    SIMILAR_CARS: "similarCars"
};

export const STICKY_TABS_GA_MAP = {
    [STICKY_TABS_VARIANT.PRICE]: b2cDetailPageEvents.priceND,
    [STICKY_TABS_VARIANT.OVERVIEW]: b2cDetailPageEvents.overviewND,
    [STICKY_TABS_VARIANT.FEATURES]: b2cDetailPageEvents.featuresND,
    [STICKY_TABS_VARIANT.CAR_CONDITION]: b2cDetailPageEvents.conditionND,
    [STICKY_TABS_VARIANT.SERVICE_HISTORY]: b2cDetailPageEvents.serviceND,
    [STICKY_TABS_VARIANT.CAR_FINANCE]: b2cDetailPageEvents.financeND,
    [STICKY_TABS_VARIANT.OUR_HAPPY_CUSTOMERS]: b2cDetailPageEvents.customersND,
    [STICKY_TABS_VARIANT.SIMILAR_CARS]: b2cDetailPageEvents.similarND
};

export const TRADE_IN_SHOWN = "trade-in-shown";

export const THIRD_FOLD_SECTIONS = [STICKY_TABS_VARIANT.SIMILAR_CARS, STICKY_TABS_VARIANT.SERVICE_HISTORY, STICKY_TABS_VARIANT.OUR_HAPPY_CUSTOMERS, STICKY_TABS_VARIANT.CAR_FINANCE];
