/* eslint-disable react/no-unknown-property */
import React from "react";
import PropTypes from "prop-types";

// Styles
import styles from "./styles.css";

// Icons
import SocialIcon from "../../shared/icon-social";

// Tracking
import { trackPlatformCustomEventsAEV2 } from "../../../tracking";

// Component
import LazyImage from "../../shared/lazy-image";

// Constants
import { DOWNLOAD_APP_URL, GEOGRAPHIES_LINKS, NUMBER } from "../../../constants/app-constants";
import { SOCIAL_URLS } from "./constants";

// Utilities
import scrollToTop from "../../../utils/helpers/scroll-to-top";
import yieldToMainThread from "../../../utils/helpers/yield-to-main-thread";
import { b2cAllPageEvents } from "../../../tracking/msite/b2c-all-page-events";

const FooterV2 = ({
    clearAllFiltersConnect,
    footerSection
}) => {

    const onAnchorClick = async (e) => {
        e.persist();
        clearAllFiltersConnect();
        await yieldToMainThread();
        scrollToTop();
        trackPlatformCustomEventsAEV2(b2cAllPageEvents.footerClick, {
            eventLabel: e?.target?.getAttribute("eventtext") || e?.target?.getAttribute("eventlabel") || "NA"
        });
    };

    const [
        usedCarsByLocation = {},
        usedCarsByBrand = {},
        resourcesLinks = {},
        sellMyCars = {},
        usefulLinks = {}
    ] = footerSection || [];

    return (
        <footer styleName={"styles.footer"}>
            <div styleName={"styles.rideWithHappiness"}>
                <h2>BETTER DRIVES, BETTER LIVES</h2>
            </div>
            <h3 styleName={"styles.heading"}>{usedCarsByLocation?.title}</h3>
            <ul styleName={"styles.footerLink"}>
                {
                    usedCarsByLocation?.items?.map((cityData, index) => {
                        const isEven = index % NUMBER.TWO === 0;
                        const colIndex =  isEven ? index / NUMBER.TWO : ((index + usedCarsByLocation?.items?.length - 1) / NUMBER.TWO);
                        const { title, redirectionUrl } = usedCarsByLocation?.items?.[colIndex] || {};
                        return (
                            <li key={`${title}_${index}`}>
                                <a onClick={onAnchorClick} eventlabel={`Popular city - ${title}`} href={redirectionUrl}>{title}</a>
                            </li>
                        );
                    })
                }
            </ul>
            <h3 styleName={"styles.heading"}>{usedCarsByBrand?.title}</h3>
            <ul styleName={"styles.footerLink"}>
                {usedCarsByBrand?.items?.map((brand, index) =>
                (<li key={`${brand.title}_${index}`}>
                    <a onClick={onAnchorClick} eventlabel={`Popular brand - ${brand.title}`} href={brand.redirectionUrl}>{brand.title}</a>
                </li>))
                }
            </ul>
            <div styleName={"styles.colsWrapper"}>
                <div styleName={"styles.sectionWrapper"}>
                    <h3 styleName={"styles.heading"}>Resources</h3>
                    <ul styleName={"styles.footerLink styles.sideBySide"}>
                        {
                            resourcesLinks?.items?.map((resourceData, index) =>
                            (<li key={`${resourceData.title}_${index}`}>
                                <a
                                    onClick={onAnchorClick}
                                    eventlabel={`Popular city - ${resourceData.title}`} href={resourceData.redirectionUrl}
                                    target={resourceData.title === "Blog" ? "_blank" : ""}
                                >
                                    {resourceData.title}
                                </a>
                            </li>))
                        }
                    </ul>
                </div>
                <div styleName={"styles.sectionWrapper"}>
                    <h3 styleName={"styles.heading  styles.sideBySide"}>Sell my car</h3>
                    <ul styleName={"styles.footerLink"}>
                        {sellMyCars?.items?.map((brand, index) =>
                        (<li key={`${brand.title}_${index}`}>
                            <a
                                onClick={onAnchorClick}
                                eventtext={brand?.label}
                                href={brand?.redirectionUrl}
                            >
                                {brand?.title}
                            </a>
                        </li>))
                        }
                    </ul>
                </div>
            </div>
            <div styleName={"styles.colsWrapper"}>
                <div styleName={"styles.sectionWrapper"}>
                    <h3 styleName={"styles.heading"}>Useful links</h3>
                    <ul styleName={"styles.footerLink styles.sideBySide"}>
                        {usefulLinks?.items?.map((usefulLink, index) => (
                            <li key={`${usefulLink?.name}_${index}`}>
                                <a
                                    onClick={onAnchorClick}
                                    href={usefulLink?.redirectionUrl}
                                    eventtext={usefulLink?.name}
                                >
                                    {usefulLink?.title}
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
                <div styleName={"styles.sectionWrapper"}>
                    <h3 styleName={"styles.heading"}>Other geographies</h3>
                    <ul styleName={"styles.footerLink styles.sideBySide"}>
                        {GEOGRAPHIES_LINKS.map((usefulLink, index) => (
                            <li key={`${usefulLink?.name}_${index}`}>
                                <a
                                    onClick={onAnchorClick}
                                    target={"_blank"}
                                    href={usefulLink?.href}
                                    eventtext={usefulLink?.name}
                                >
                                    {usefulLink?.name}
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <h3 styleName={"styles.whiteHeading"}>KEEP IN TOUCH</h3>
            <ul styleName={"styles.socialMedia"}>
                {SOCIAL_URLS.map((social, index) => (
                    <li key={`${social?.altText}_${index}`}>
                        <a
                            href={social?.href}
                            target="_blank"
                            aria-label={`visit CARS24 on ${social?.altText}`}
                        >
                            <LazyImage src={social?.imgSrc} alt={social?.altText} />
                        </a>
                    </li>
                ))}
            </ul>
            <h3 styleName={"styles.whiteHeading"}>EXPERIENCE US ON MOBILE APP</h3>
            <div styleName={"styles.footerlinkApp"}>
                <a href={DOWNLOAD_APP_URL} styleName={"styles.playIcon"}>
                    download on the App Store
                    <SocialIcon name="appStore" />
                </a>
                <a href={DOWNLOAD_APP_URL} styleName={"styles.playIcon"}>
                    Get it on Google Play
                    <SocialIcon name="googlePlay" />
                </a>
            </div>
            <div styleName={"styles.copyrightGrid"}>
                <span>© 2019  www.cars24.com</span>
                <span>All rights reserved</span>
            </div>
        </footer>
    );
};

FooterV2.propTypes = {
    clearAllFiltersConnect: PropTypes.func,
    footerSection: PropTypes.array
};

export default FooterV2;
