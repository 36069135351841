import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    SET_RETURN_REASON
    SET_INITIAL_STATE
    SET_ADDRESS
    FETCH_ORDER_RETURN_SLOT_DETAILS_INIT
    FETCH_RETURN_SLOT_AVAILABILITY_SUCCESS
    FETCH_RETURN_SLOT_AVAILABILITY_FAILURE
    SET_RETURN_SELECTED_SLOT
`,
    {
        prefix: "schedulereturnslotinfo/"
    }
);
