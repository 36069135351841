import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";

export const INITIAL_STATE = {
    carDetail: {},
    isLoading: false,
    error: null
};

const fetchFeedbackDetailsInit = (state = INITIAL_STATE) => {
    return { ...state, isLoading: true };
};

const setCarDetailsContent = (state = INITIAL_STATE, { data }) => {
    return { ...state, carDetail: data };
};

const fetchFeedbackDetailsSuccess = (state = INITIAL_STATE, {data}) => {
    return { ...state, error: null, isLoading: false, ...data };
};

const fetchFeedbackDetailsFailure = (state = INITIAL_STATE, {error}) => {
    return { ...state, isLoading: false, error };
};

export const HANDLERS = {
    [Types.FETCH_FEEDBACK_INIT]: fetchFeedbackDetailsInit,
    [Types.FETCH_FEEDBACK_SUCCESS]: fetchFeedbackDetailsSuccess,
    [Types.FETCH_FEEDBACK_FAILURE]: fetchFeedbackDetailsFailure,
    [Types.SET_CAR_DETAIL_CONTENT]: setCarDetailsContent
};

export default createReducer(INITIAL_STATE, HANDLERS);
