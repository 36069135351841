import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";

export const INITIAL_STATE = {
    status: null,
    error: null,
    isFetching: false
};

export const fetchPaymentStatusInit = (state = INITIAL_STATE) => {
    return { ...state, isFetching: true };
};

export const fetchPaymentStatusSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        status: data.status,
        isFetching: false
    };
};

export const fetchPaymentStatusFailure = (state = INITIAL_STATE, { error }) => {
    return { ...state, error, isFetching: false };
};

export const HANDLERS = {
    [Types.FETCH_PAYMENT_STATUS_INIT]: fetchPaymentStatusInit,
    [Types.FETCH_PAYMENT_STATUS_SUCCESS]: fetchPaymentStatusSuccess,
    [Types.FETCH_PAYMENT_STATUS_FAILURE]: fetchPaymentStatusFailure
};

export default createReducer(INITIAL_STATE, HANDLERS);
