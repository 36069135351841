import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ReturnRepairChatInfo from "./components";
import { fetchTicketDetail, updateTicketDetail } from "./actions";

const mapStateToProps = ({
    user: {
        isFetched: isUserDetailsFetched,
        isWebview
    }
}) => ({
    isUserDetailsFetched,
    isWebview
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchTicketDetailConnect: fetchTicketDetail,
    updateTicketDetailConnect: updateTicketDetail
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ReturnRepairChatInfo);
