import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";

export const INITIAL_STATE = {
    data: {},
    error: null,
    isLoading: false,
    isFetched: false
};

export const fetchSalesforceDetailsInitiated = (state = INITIAL_STATE) => {
    return { ...state, isLoading: true};
};

export const fetchSalesforceDetailsSuccess = (state = INITIAL_STATE, { data }) => {
    return { ...state, isLoading: false, data, error: null,  isFetched: false };
};

export const fetchSalesforceDetailsFailure = (state = INITIAL_STATE,  { error }) => {
    return { ...state, isLoading: false, error,  isFetched: false };
};

export const updateDocumentInit = (state = INITIAL_STATE) => {
    return { ...state, isLoading: true };
};

export const updateDocumentSuccess = (state = INITIAL_STATE, { data }) => {
    return { ...state, isLoading: false, data };
};

export const updateDocumentFailure = (state = INITIAL_STATE, { error }) => {
    return { ...state, isLoading: false, error };
};

export const submitDocumentsDetailsSuccess = (state = INITIAL_STATE) => {
    return { ...state, isLoading: false, data: { docs: state.data.docs, isDocxSubmitted: true }, error: null };
};

export const submitDocumentsDetailsFailure = (state = INITIAL_STATE, { error }) => {
    return { ...state, isLoading: false, error };
};
export const submitFinanceDetailsSuccess = (state = INITIAL_STATE) => {
    return { ...state, isLoading: false };
};

export const submitFinanceDetailsFailure = (state = INITIAL_STATE, { error }) => {
    return { ...state, isLoading: false, error };
};

export const HANDLERS = {
    [Types.FETCH_SALESFORCE_DETAILS_INITIATED]: fetchSalesforceDetailsInitiated,
    [Types.FETCH_SALESFORCE_DETAILS_SUCCESS]: fetchSalesforceDetailsSuccess,
    [Types.FETCH_SALESFORCE_DETAILS_FAILURE]: fetchSalesforceDetailsFailure,
    [Types.UPDATE_DOCUMENT_INITIATED]: updateDocumentInit,
    [Types.UPDATE_DOCUMENT_SUCCESS]: updateDocumentSuccess,
    [Types.UPDATE_DOCUMENT_FAILURE]: updateDocumentFailure,
    [Types.SUBMIT_DOCUMENTS_DETAILS_SUCCESS]: submitDocumentsDetailsSuccess,
    [Types.SUBMIT_DOCUMENTS_DETAILS_FAILURE]: submitDocumentsDetailsFailure,

    [Types.SUBMIT_FINANCE_DETAILS_SUCCESS]: submitDocumentsDetailsSuccess,
    [Types.SUBMIT_FINANCE_DETAILS_FAILURE]: submitDocumentsDetailsFailure

};

export default createReducer(INITIAL_STATE, HANDLERS);
