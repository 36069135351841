import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    API_REQUEST
    API_RESPONSE
    SET_SELECTED_STEP
    SET_SELECTED_PACKAGE
    SET_PRICE_DETAILS
    SET_LIST_PACKAGES
`,
    {
        prefix: "c2cmarketplace/"
    }
);
