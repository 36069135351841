import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";

export const INITIAL_STATE = {
    isDesktopModalView: false,
    sourceUrl: ""
};

export const updateIsDesktopModalView = (state = INITIAL_STATE, { isDesktopModalView, sourceUrl  }) => {
    return { ...state, isDesktopModalView, sourceUrl: sourceUrl || state.sourceUrl };
};

export const HANDLERS = {
    [Types.UPDATE_IS_DESKTOP_MODAL_VIEW]: updateIsDesktopModalView
};

export default createReducer(INITIAL_STATE, HANDLERS);
