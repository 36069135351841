import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    FETCH_POST_PRICE_REQUEST
    FETCH_POST_PRICE_SUCCESS
    FETCH_POST_PRICE_FAILURE

    SET_UPLOADING_STATUS
    SET_IS_UPDATING

    FILL_BANK_DETAILS
    SET_PAYMENT_MODE
    UPDATE_RTA_DETAILS
    UPDATE_SELLER_NAME
`,
    {
        prefix: "sellercarhandover/"
    }
);
