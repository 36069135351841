import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    FETCH_FEEDBACK_INIT
    FETCH_FEEDBACK_SUCCESS
    FETCH_FEEDBACK_FAILURE
    SET_CAR_DETAIL_CONTENT
`,
    {
        prefix: "feedback/"
    }
);
