import { createReducer } from "../../../utils/redux/create-reducer";
import { NUMBER } from "../../../constants/app-constants";
import { STEP_KEY, STEP_MAP } from "./constant";

import Types from "./types";

export const INITIAL_STATE = {
    currStep: "",
    localCurrentStep: -NUMBER.ONE,
    userDetails: {
        manufacturerId: null,
        modelId: null,
        variantId: null,
        maxLoanAmount: null,
        maxLoanTenure: null,
        reqLoanAmount: null,
        vehicleDepositAmount: null,
        reqLoanTenure: null,
        applicationType: null,
        firstName: null,
        middleName: null,
        lastName: null,
        dob: null,
        nationality: null,
        gender: null,
        maritalStatus: null,
        enbdSlryAcctNo: null,
        passportNo: null,
        passportIssueDate: null,
        passportExpiryDate: null,
        visaNo: null,
        visaIssueDate: null,
        visaExpiryDate: null,
        emiratesIdNo: null,
        emiratesIdIssueDate: null,
        emiratesIdExpiryDate: null,
        employmentType: null,
        employerName: null,
        employerIndustry: null,
        employerOrgNature: null,
        employerOrgType: null,
        lengthOfServices: null,
        dateOfJoining: null,
        employmentStatus: null,
        employeeNumber: null,
        designation: null,
        durationInUae: null,
        salaryAmt: null,
        otherIncomeAmt: null,
        otherIncomeDtls: null,
        allowanceAmt: null,
        allowanceDscrptn: null,
        deductions: null,
        email: null,
        homeNo: null,
        alternateContactNo: null
    },
    errorWhileLoading: false,
    isLoading: true,
    isFetched: false,
    errorDetails: null
};

export const fetchLoanPreApprovalInit = (state = INITIAL_STATE) => {
    return { ...state, isLoading: true, isFetched: false };
};

export const fetchLoanPreApprovalSuccess = (state = INITIAL_STATE, { data }) => {
    const { userDetailReqResp = {}, userSelection = {} } = data || {};
    const { currStep = STEP_KEY.PERSONAL, userDetails = {} } = userDetailReqResp || {};
    return {
        ...state,
        currStep,
        localCurrentStep: STEP_MAP[currStep || STEP_KEY?.PERSONAL],
        userDetails: {
            ...state.userDetails,
            ...userDetails
        },
        userSelection,
        errorWhileLoading: false,
        isLoading: false,
        isFetched: true,
        errorDetails: null
    };
};

export const fetchLoanPreApprovalFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        errorWhileLoading: true,
        isLoading: false,
        isFetched: true,
        errorDetails: error
    };
};

export const fetchLoanPreApprovalUserSelection = (state = INITIAL_STATE, { data }) => {
    const { userSelection = {} } = data || {};
    return {
        ...state,
        userSelection
    };
};

export const updateStep = (state = INITIAL_STATE, { localCurrentStep, currStep }) => {
    return {
        ...state,
        localCurrentStep,
        ...(currStep && { currStep }
    )};
};

export const updateUserDetails = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        userDetails: {
            ...state.userDetails,
            ...data
        }
    };
};

export const HANDLERS = {
    [Types.UPDATE_STEP]: updateStep,
    [Types.UPDATE_USER_DETAILS]: updateUserDetails,
    [Types.FETCH_LOAN_PRE_APPROVAL_INIT]: fetchLoanPreApprovalInit,
    [Types.FETCH_LOAN_PRE_APPROVAL_SUCCESS]: fetchLoanPreApprovalSuccess,
    [Types.FETCH_LOAN_PRE_APPROVAL_FAILURE]: fetchLoanPreApprovalFailure,
    [Types.FETCH_LOAN_PRE_APPROVAL_USER_SELECTION]: fetchLoanPreApprovalUserSelection
};

export default createReducer(INITIAL_STATE, HANDLERS);
