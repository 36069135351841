import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";

export const SELECTED_SLOT_INITIAL_STATE = {
    slotDate: null,
    slotTime: null
};

export const INITIAL_ADDRESS_STATE = {
    addressLine1: null,
    addressLine2: null
};

export const INITIAL_RESCHEDULE_STATE = {
    selectedReason: null
};

export const INITIAL_RETURN_REASON_STATE = {
    selectedReturnOptions: null,
    selectedDescription: null,
    orderId: null,
    ticketType: null,
    reasonsList: null
};

export const INITIAL_STATE = {
    returnSlotAvailabilityDetails: {
        appointmentId: "",
        orderId: null,
        regionCode: null,
        locationCode: null,
        zoneId: null,
        isFetched: false,
        isLoading: false,
        error: null,
        availableReturnSlots: []
    },
    selectedSlotDateTime: SELECTED_SLOT_INITIAL_STATE,
    addressDetails: INITIAL_ADDRESS_STATE,
    rescheduleDetails: INITIAL_RESCHEDULE_STATE,
    returnReasonDescription: INITIAL_RETURN_REASON_STATE
};

const fetchReturnSlotPostBcDetailsInit = (state = INITIAL_STATE) => {
    return {
        ...state,
        returnSlotAvailabilityDetails: {
            ...state.slotAvailabilityDetails,
            isLoading: true
        }
    };
};

const setReturnIntitalState = (state = INITIAL_STATE) => {
    return {
        ...INITIAL_STATE, state
    };
};

const fetchReturnSlotAvailabilityDetailsSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        returnSlotAvailabilityDetails: {
            ...state.slotAvailabilityDetails,
            error: null,
            isLoading: false,
            isFetched: true,
            ...data
        }
    };
};

const fetchReturnSlotAvailabilityDetailsFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        returnSlotAvailabilityDetails: {
            ...state.returnSlotAvailabilityDetails,
            error,
            isLoading: false,
            isFetched: true
        }
    };
};

const setReturnSelectedSlot = (state = INITIAL_STATE, { selectedSlot }) => {
    return {
        ...state,
        selectedSlotDateTime: {
            ...state.selectedSlotDateTime,
            selectedSlot
        }
    };
};

const setAddress = (state = INITIAL_STATE, { addressDetails }) => {
    return {
        ...state,
        addressDetails
    };
};

const setReturnReason = (state = INITIAL_STATE, { returnReasonDescription }) => {
    return {
        ...state,
        returnReasonDescription
    };
};

export const HANDLERS = {
    [Types.SET_INITIAL_STATE]: setReturnIntitalState,
    [Types.SET_RETURN_REASON]: setReturnReason,
    [Types.SET_ADDRESS]: setAddress,
    [Types.SET_RETURN_SELECTED_SLOT]: setReturnSelectedSlot,
    [Types.FETCH_ORDER_RETURN_SLOT_DETAILS_INIT]: fetchReturnSlotPostBcDetailsInit,
    [Types.FETCH_RETURN_SLOT_AVAILABILITY_SUCCESS]: fetchReturnSlotAvailabilityDetailsSuccess,
    [Types.FETCH_RETURN_SLOT_AVAILABILITY_FAILURE]: fetchReturnSlotAvailabilityDetailsFailure
};

export default createReducer(INITIAL_STATE, HANDLERS);
