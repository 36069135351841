import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";

const LOAN_ELIGIBILITY_INITIAL_STATE = {
    isLoading: false,
    data: null,
    error: null
};

const USER_LOAN_ELIGIBILITY_INITIAL_STATE = {
    isLoading: false,
    data: null,
    error: null
};

export const INITIAL_STATE = {
    isEmiCalculatorLoading: false,
    isEmiCalculatorFetched: false,
    emiCalculator: {},
    loanEligibility: LOAN_ELIGIBILITY_INITIAL_STATE,
    userLoanEligibility: USER_LOAN_ELIGIBILITY_INITIAL_STATE
};

// FETCH EMI CALCULATOR
const fetchEmiCalculatorInit = (state = INITIAL_STATE) => {
    return {
        ...state,
        isEmiCalculatorLoading: true,
        isEmiCalculatorFetched: false
    };
};

const fetchEmiCalculatorSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        isEmiCalculatorLoading: false,
        isEmiCalculatorFetched: true,
        emiCalculator: data
    };
};

const fetchEmiCalculatorFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        isEmiCalculatorLoading: false,
        isEmiCalculatorFetched: true,
        emiCalculator: {}
    };
};

// CHECK LOAN ELIGIBILITY
const loanEligibilityInit = (state = INITIAL_STATE) => {
    return {
        ...state,
        loanEligibility: {
            ...LOAN_ELIGIBILITY_INITIAL_STATE,
            isLoading: true
        }
    };
};

const loanEligibilitySuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        loanEligibility: {
            ...LOAN_ELIGIBILITY_INITIAL_STATE,
            isLoading: false,
            data
        }
    };
};

const loanEligibilityFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        loanEligibility: {
            ...LOAN_ELIGIBILITY_INITIAL_STATE,
            isLoading: false,
            error
        }
    };
};

// CHECK USER LOAN ELIGIBILITY
const userLoanEligibilityInit = (state = INITIAL_STATE) => {
    return {
        ...state,
        userLoanEligibility: {
            ...USER_LOAN_ELIGIBILITY_INITIAL_STATE,
            isLoading: true
        }
    };
};

const userLoanEligibilitySuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        userLoanEligibility: {
            ...USER_LOAN_ELIGIBILITY_INITIAL_STATE,
            isLoading: false,
            data
        }
    };
};

const userLoanEligibilityFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        userLoanEligibility: {
            ...USER_LOAN_ELIGIBILITY_INITIAL_STATE,
            isLoading: false,
            error
        }
    };
};

export const HANDLERS = {
    // EMI CALCULATOR
    [Types.FETCH_EMI_CALCULATOR_INIT]: fetchEmiCalculatorInit,
    [Types.FETCH_EMI_CALCULATOR_SUCCESS]: fetchEmiCalculatorSuccess,
    [Types.FETCH_EMI_CALCULATOR_FAILURE]: fetchEmiCalculatorFailure,

    // LOAN ELIGIBILITY
    [Types.LOAN_ELIGIBILITY_INIT]: loanEligibilityInit,
    [Types.LOAN_ELIGIBILITY_SUCCESS]: loanEligibilitySuccess,
    [Types.LOAN_ELIGIBILITY_FAILURE]: loanEligibilityFailure,

    // USER LOAN ELIGIBILITY
    [Types.USER_LOAN_ELIGIBILITY_INIT]: userLoanEligibilityInit,
    [Types.USER_LOAN_ELIGIBILITY_SUCCESS]: userLoanEligibilitySuccess,
    [Types.USER_LOAN_ELIGIBILITY_FAILURE]: userLoanEligibilityFailure
};

export default createReducer(INITIAL_STATE, HANDLERS);
