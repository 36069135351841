import React from "react";
import PropTypes from "prop-types";
import loadable from "@loadable/component";
import { withRouter } from "react-router-dom";
import { renderRoutes } from "react-router-config";

// Pages
import AEConfiguration from "./components/ae.configuration";

// Constant
import { ROUTE_PATHS, POSTBC_ROUTES, ROUTE_NAME } from "./constants/routes-constants";
import PageLoader from "./components/shared/page-loader";
import ReturnRepairChat from "./pages/ae.mobile/return-repair-chat";
import { SELLER_HOME_ROUTE } from "./constants/ae/sell-car-online";
// import CarCare from "./pages/ae.mobile/car-care/component.js";

// adding loadable component for all routes.
const Home = loadable(() => import("./pages/ae.mobile/home"), { ssr: true, fallback: <PageLoader />});
const CarListing = loadable(() => import("./pages/ae.mobile/car-listing"), { ssr: true, fallback: <PageLoader /> });
const CarDetail = loadable(() => import("./pages/ae.mobile/car-detail"), { ssr: true, fallback: <PageLoader /> });
const PrivacyPolicy = loadable(() => import("./pages/ae.mobile/privacy-policy"), { fallback: <PageLoader /> });
const PaymentProcessorDesktop = loadable(() => import("./pages/ae.mobile/payment-processor-desktop"), { fallback: <PageLoader /> });
const AboutUs = loadable(() => import("./pages/ae.mobile/about-us"), { fallback: <PageLoader /> });
const ContactUs = loadable(() => import("./pages/ae.mobile/contact-us"), { fallback: <PageLoader /> });
const Faq = loadable(() => import("./pages/ae.mobile/faq"), { fallback: <PageLoader /> });
const HelpCenter = loadable(() => import("./pages/ae.mobile/help-center"), { fallback: <PageLoader /> });
const HelpCenterPageDesktopModal = loadable(() => import("./pages/ae.mobile/help-center-desktop-modal"), { fallback: <PageLoader /> });
const UserReviews = loadable(() => import("./pages/ae.mobile/user-reviews"), { fallback: <PageLoader /> });
const PaymentStatus = loadable(() => import("./pages/ae.mobile/payment-status"), { fallback: <PageLoader /> });
const EpicDropSale = loadable(() => import("./pages/ae.mobile/epic-drop-sale"), { fallback: <PageLoader /> });
const SeoCarDetails = loadable(() => import("./pages/ae.mobile/seo-car-details"), { fallback: <PageLoader /> });
const MyBookings = loadable(() => import("./pages/ae.mobile/my-bookings"));
const MyWishlist = loadable(() => import("./pages/ae.mobile/my-wishlist"));
const TermsOfUse = loadable(() => import("./pages/ae.mobile/terms-of-use"));
const NotFound = loadable(() => import("./pages/ae.mobile/not-found"));
const SiteMap = loadable(() => import("./pages/ae.mobile/sitemap"));
const Profile = loadable(() => import("./pages/ae.mobile/profile"));
const CarDetailReport = loadable(() => import("./pages/ae.mobile/car-details-report"));
const HowToBuy = loadable(() => import("./pages/ae.mobile/how-to-buy"));
const CarDetailFeatures = loadable(() => import("./pages/ae.mobile/car-details-features"));
const Checkout = loadable(() => import("./pages/ae.mobile/checkout"), { fallback: <PageLoader /> });
const PostBookingConfirmation = loadable(() => import("./pages/ae.mobile/post-bc"), { fallback: <PageLoader /> });
const PostBookingSteps = loadable(() => import("./pages/ae.mobile/post-bc-step-mapper"), { fallback: <PageLoader /> });
const PostBcVas = loadable(() => import("./pages/ae.mobile/post-bc-vas"), { fallback: <PageLoader /> });
const VasInsuranceComparison = loadable(() => import("./pages/ae.mobile/vas-insurance-comparison"));
const Feedback = loadable(() => import("./pages/ae.mobile/feedback"));
const OrderTransaction = loadable(() => import("./pages/ae.mobile/order-transaction"));
const PaymentMethod = loadable(() => import("./pages/ae.mobile/payment-method"), {
    fallback: <PageLoader />
});
const ModalPage = loadable(() => import("./pages/ae.mobile/modal-page"));
const TestDriveSchedule = loadable(() => import("./pages/ae.mobile/test-drive-schedule"), {
    fallback: <PageLoader />
});
const DeliverySchedule = loadable(() => import("./pages/ae.mobile/delivery-schedule"), {
    fallback: <PageLoader />
});
const SalesforceUploads = loadable(() => import("./pages/ae.mobile/salesforce"));
const CarComparison = loadable(() => import("./pages/ae.mobile/car-comparison"));
const ViewAllTickets = loadable(() => import("./pages/ae.mobile/view-all-tickets"), {
    fallback: <PageLoader />
});
const CarRepair = loadable(() => import("./pages/ae.mobile/repair-car"), {
    fallback: <PageLoader />
});
const CarReturn = loadable(() => import("./pages/ae.mobile/return-car"), {
    fallback: <PageLoader />
});
const ChooseSlot = loadable(() => import("./pages/ae.mobile/choose-slot"), {
    fallback: <PageLoader />
});
const CarReturnAddress = loadable(() => import("./pages/ae.mobile/return-address"), {
    fallback: <PageLoader />
});
const LoanPreApproval = loadable(() => import("./pages/ae.mobile/loan-pre-approval"), {
    fallback: <PageLoader />
});
const CarLoan = loadable(() => import("./pages/ae.mobile/car-loan"), {
    fallback: <PageLoader />
});
const TofService = loadable(() => import("./pages/ae.mobile/tof-service"), {
    fallback: <PageLoader />
});
const TofServiceHome = loadable(() => import("./pages/ae.mobile/tof-service-home"), {
    fallback: <PageLoader />
});

const TofServiceFlowDesktop = loadable(() => import("./pages/ae.mobile/tof-service-flow-desktop"), {
    fallback: <PageLoader />
});
const TofServiceHomeAppBackwardCompatible = loadable(() => import("./pages/ae.mobile/tof-service-home-app-backward-compatible"), {
    fallback: <PageLoader />
});
const TofServiceOrdersListing = loadable(() => import("./pages/ae.mobile/tof-service-orders-listing"), {
    fallback: <PageLoader />
});
const TofServiceSchedule = loadable(() => import("./pages/ae.mobile/tof-service-schedule"), {
    fallback: <PageLoader />
});
const TofServiceAnchor = loadable(() => import("./pages/ae.mobile/tof-service-anchor"), {
    fallback: <PageLoader />
});
const SalesAgreement = loadable(() => import("./pages/ae.mobile/sales-agreement/index.js"), {
    fallback: <PageLoader />
});
const NPSFeedback = loadable(() => import("./pages/ae.mobile/nps-feedback"), {
    fallback: <PageLoader />
});
const SimilarCars = loadable(() => import("./pages/ae.mobile/similar-cars"), {
    fallback: <PageLoader />
});
const TailoredCarsList = loadable(() => import("./pages/ae.mobile/tailored-cars"), {
    fallback: <PageLoader />
});
const AiRecommendedCars = loadable(() => import("./pages/ae.mobile/ai-recommended-cars"), {
    fallback: <PageLoader />
});
const LocationCapture = loadable(() => import("./pages/ae.mobile/location-onboarding"), {
    fallback: <PageLoader />
});
const CarCare = loadable(() => import("./pages/ae.mobile/car-care/component.js"), {
    fallback: <PageLoader />
});

// Seller pages
const CarEvaluation = loadable(() => import("./pages/ae.mobile/car-evaluation"));
const SellOnline = loadable(() => import("./pages/ae.mobile/sell-online"), {
    fallback: <PageLoader />
});

//Bank Valuation Certificate Routes
const BankValuationCertificate = loadable(() => import("./pages/ae.mobile/bank-valuation-certificate/index.js"), {
    fallback: <PageLoader />
});

const BankValuationCertificateDesktopFlow = loadable(() => import("./pages/ae.mobile/bank-valuation-certificate-desktop-flow"), {
    fallback: <PageLoader />
});

const BankValuationCertificateCarEvaulation = loadable(() => import("./pages/ae.mobile/bvc-car-evaluation/index.js"), {
    fallback: <PageLoader />
});
const BankValuationCertificateOrderSummary = loadable(() => import("./pages/ae.mobile/bvc-order-summary/index.js"), {
    ssr: false,
    fallback: <PageLoader />
});
const BvcFileUpload = loadable(() => import("./pages/ae.mobile/bvc-file-upload/index.js"), {
    fallback: <PageLoader />
});
const SellCarBooking = loadable(() => import("./pages/ae.mobile/sell-car-booking"));
const SellerRevaluation = loadable(() => import("./pages/ae.mobile/seller-revaluation"));
const SellCarDetails = loadable(() => import("./pages/ae.mobile/sell-car-details"));
const SellerPostPriceAcceptance = loadable(() => import("./pages/ae.mobile/seller-post-price-acceptance"));
const SellerNps = loadable(() => import("./pages/ae.mobile/seller-nps/index"));

// C2C Routes
const C2CDirectHome = loadable(() => import("./pages/ae.mobile/direct-home"));
const SellerCenter = loadable(() => import("./pages/ae.mobile/seller-center"));

const Root = ({ route, children }) => {
    return (
        <AEConfiguration>
            {renderRoutes(route.routes)}
            {children}
        </AEConfiguration>
    );
};

Root.propTypes = {
    route: PropTypes.object,
    children: PropTypes.object
};

const routes = [
    {
        path: "/",
        component: withRouter(Root),
        init: "./init-top.ae.mobile",
        routes: [
            {
                path: "/",
                exact: true,
                name: "ae.home",
                isUsedCarRoute: true,
                component: Home,
                init: "./ae/home.mobile"
            },
            {
                path: `/${ROUTE_PATHS.carRepair}/:appId`,
                exact: true,
                name: `ae-${ROUTE_PATHS.carRepair}`,
                isUsedCarRoute: true,
                component: CarRepair,
                init: "./ae/postbc.mobile"
            },
            {
                path: `/${ROUTE_PATHS.carAddress}/:appId`,
                exact: true,
                name: `ae-${ROUTE_PATHS.carAddress}`,
                isUsedCarRoute: true,
                component: CarReturnAddress,
                init: "./ae/postbc.mobile"
            },
            {
                path: `/${ROUTE_PATHS.carReturn}/:appId`,
                exact: true,
                name: `ae-${ROUTE_PATHS.carReturn}`,
                isUsedCarRoute: true,
                component: CarReturn,
                init: "./ae/postbc.mobile"
            },
            {
                path: `/${ROUTE_PATHS.loanPreApproval}/:orderId`,
                exact: true,
                component: LoanPreApproval,
                isUsedCarRoute: true,
                name: `ae-${ROUTE_PATHS.loanPreApproval}`
            },
            {
                path: `/${ROUTE_PATHS.chooseSlot}/:appId`,
                exact: true,
                name: `ae-${ROUTE_PATHS.chooseSlot}`,
                isUsedCarRoute: true,
                component: ChooseSlot,
                init: "./ae/postbc.mobile"
            },
            {
                // car evalution and appointment booking
                path: `/${ROUTE_PATHS.carEvaluation}/:stepid?`,
                exact: true,
                component: CarEvaluation,
                isUsedCarRoute: false,
                name: `ae.${ROUTE_PATHS.carEvaluation}`
            },
            {
                // post booking confirmation journey
                path: `/${ROUTE_PATHS.postBooking}/:lid`,
                exact: true,
                isUsedCarRoute: true,
                component: PostBookingConfirmation,
                name: `ae-${ROUTE_PATHS.postBooking}`,
                init: "./ae/postbc.mobile"
            },
            {
                // post booking steps mapper
                path: `/${ROUTE_PATHS.postBooking}/:lid/:stepid`,
                exact: true,
                isUsedCarRoute: true,
                component: PostBookingSteps,
                name: `ae-${ROUTE_PATHS.postBooking}`,
                init: "./ae/postbc.mobile"
            },
            {
                // NPS Delivery Feedback
                path: `/${ROUTE_PATHS.deliveryFeedback}/:orderId/:appointmentId`,
                exact: true,
                component: NPSFeedback,
                name: `${ROUTE_PATHS.deliveryFeedback}`,
                init: "./ae/nps-delivery-feedback.mobile"
            },
            {
                // Similar cars
                path: `/${ROUTE_PATHS.similarCars}`,
                exact: true,
                component: SimilarCars,
                name: `ae.similar-cars`
            },
            {
                // Hand picked cars
                path: `/${ROUTE_PATHS.personalizedCars}`,
                exact: true,
                component: TailoredCarsList,
                name: `ae.tailored-car-list`
            },
            {
                // Sales Agreement
                path: `/${ROUTE_PATHS.salesAgreement}/:appointmentId`,
                exact: true,
                component: SalesAgreement,
                isUsedCarRoute: true,
                name: `ae-${ROUTE_PATHS.salesAgreement}`
            },
            {
                // post booking confirmation journey
                path: `/${ROUTE_PATHS.vas}/:orderId/:lid/compare-insurance`,
                exact: true,
                component: VasInsuranceComparison,
                isUsedCarRoute: true,
                name: `ae-${ROUTE_PATHS.vas}-compare-insurance`,
                init: "./ae/postbc.mobile"
            },
            {
                // post booking confirmation journey
                path: `/${ROUTE_PATHS.vas}/:orderId/:lid/:stepid`,
                exact: true,
                component: PostBcVas,
                isUsedCarRoute: true,
                name: `ae-${ROUTE_PATHS.vas}`,
                init: "./ae/postbc.mobile"
            },
            {
                // Order Transaction History
                path: `/${ROUTE_PATHS.payment}/:orderId/transaction`,
                exact: true,
                component: OrderTransaction,
                isUsedCarRoute: true,
                name: `ae-${ROUTE_PATHS.payment}-transaction`
            },
            {
                // post booking confirmation journey
                path: `/${ROUTE_PATHS.postBooking}/:lid/${POSTBC_ROUTES.testDrive}/schedule/:stepid`,
                exact: true,
                component: TestDriveSchedule,
                isUsedCarRoute: true,
                name: `ae-${ROUTE_PATHS.postBooking}-tdschedule`,
                init: "./ae/postbc.mobile"
            },
            {
                path: `/${ROUTE_PATHS.postBooking}/:lid/${POSTBC_ROUTES.delivery}/schedule/:stepid`,
                exact: true,
                component: DeliverySchedule,
                isUsedCarRoute: true,
                name: `ae-${ROUTE_PATHS.postBooking}-deliverySchedule`,
                init: "./ae/postbc.mobile"
            },
            {
                // User Feedback
                path: `/${ROUTE_PATHS.feedback}/:orderId/:feedbackType`,
                exact: true,
                component: Feedback,
                isUsedCarRoute: true,
                name: `ae-${ROUTE_PATHS.feedback}`,
                init: "./ae/feedback.mobile"
            },
            {
                path: "/buy-used-:carMakeModel-cars-:city-:lid(\\d+)",
                exact: true,
                component: CarDetail,
                isUsedCarRoute: true,
                name: "ae.car-details",
                init: "./ae/details.mobile"
            },
            {
                path: "/new-car/:make/:model/:pageType?",
                exact: true,
                component: SeoCarDetails,
                isUsedCarRoute: true,
                name: ROUTE_NAME.SEO_CAR_DETAILS,
                init: "./ae/seo-details.mobile"
            },
            {
                path: "/buy-used-:carMakeModel-cars-:city-:lid(\\d+)/features",
                exact: true,
                component: CarDetailFeatures,
                isUsedCarRoute: true,
                name: "ae.car-details-features",
                init: "./ae/details.mobile"
            },
            {
                path: "/buy-used-:carMakeModel-cars-:city-:lid(\\d+)/report",
                exact: true,
                component: CarDetailReport,
                isUsedCarRoute: true,
                name: "ae.car-details-report",
                init: "./ae/details.mobile"
            },
            {
                path: "/buy-used-:carMakeModel-cars-:city-:lid(\\d+)/checkout",
                exact: true,
                component: Checkout,
                isUsedCarRoute: true,
                name: "ae.car-details-checkout",
                init: "./ae/details.mobile"
            },
            {
                path: "/buy-used-:filterQuery",
                exact: true,
                name: "ae.listing",
                component: CarListing,
                isUsedCarRoute: true,
                init: "./ae/listing.mobile"
            },
            {
                path: "/car-comparison",
                exact: true,
                isUsedCarRoute: true,
                component: CarComparison,
                name: "ae.car-comparison",
                init: "./ae/comparison.mobile"
            },
            {
                path: "/my-bookings/:bookingId?",
                exact: true,
                isUsedCarRoute: true,
                component: MyBookings,
                name: "ae.my-bookings"
            },
            {
                path: "/my-wishlist",
                exact: true,
                component: MyWishlist,
                name: "ae.my-wishlist"
            },
            {
                path: `/${ROUTE_PATHS.carLoan}`,
                exact: true,
                isUsedCarRoute: true,
                component: CarLoan,
                name: `ae.${ROUTE_PATHS.carLoan}`,
                init: "./ae/car-loan.mobile"
            },
            {
                path: `/${ROUTE_PATHS.carServicing}`,
                exact: true,
                isUsedCarRoute: true,
                component: TofServiceHome,
                name: ROUTE_NAME.SERVICE_CONTRACT_HOME,
                init: "./ae/tof-service.mobile"
            },
            {
                path: `/${ROUTE_PATHS.carServicing}-desktop`,
                exact: true,
                isUsedCarRoute: true,
                component: TofServiceFlowDesktop,
                name: ROUTE_NAME.SERVICE_CONTRACT_HOME,
                init: "./ae/tof-service.mobile"
            },
            {
                path: `/${"service-contract"}`,
                exact: true,
                isUsedCarRoute: true,
                component: TofServiceHomeAppBackwardCompatible,
                name: ROUTE_NAME.SERVICE_CONTRACT_HOME,
                init: "./ae/tof-service.mobile"
            },
            {
                path: `/${ROUTE_PATHS.tofService}/:stepid`,
                exact: true,
                component: TofService,
                name: ROUTE_NAME.SERVICE_CONTRACT
            },
            {
                path: `/${ROUTE_PATHS.myServiceOrders}`,
                exact: true,
                isUsedCarRoute: true,
                component: TofServiceOrdersListing,
                name: ROUTE_NAME.MY_SERVICE_ORDERS
            },
            {
                path: `/${ROUTE_PATHS.tofService}/:orderId/schedule/:stepid`,
                exact: true,
                isUsedCarRoute: true,
                component: TofServiceSchedule,
                name: ROUTE_NAME.TOF_SERVICE_SCHEDULE
            },
            {
                path: `/${ROUTE_PATHS.tofService}/:orderId/anchor/:stepId?`,
                exact: true,
                isUsedCarRoute: true,
                component: TofServiceAnchor,
                name: ROUTE_NAME.TOF_SERVICE_ANCHOR
            },
            {
                path: "/payment-processor-desktop",
                exact: true,
                name: "payment-processor-desktop",
                component: PaymentProcessorDesktop
            },
            {
                path: "/about-us",
                exact: true,
                name: "about-us",
                component: AboutUs
            },
            {
                path: `/${ROUTE_PATHS.epicCarSale}`,
                exact: true,
                name: `ae.${ROUTE_PATHS.epicCarSale}`,
                component: EpicDropSale
            },
            {
                path: "/user-reviews",
                exact: true,
                name: "user-reviews",
                component: UserReviews
            },
            {
                path: "/contact",
                exact: true,
                name: "contact-us",
                component: ContactUs
            },
            {
                path: "/how-to-buy",
                exact: true,
                name: "how-to-buy",
                component: HowToBuy
            },
            {
                path: "/privacy-policy",
                exact: true,
                name: "privacy-policy",
                component: PrivacyPolicy
            },
            {
                path: "/chat/:tid/:appId",
                exact: true,
                name: `ae-${ROUTE_PATHS.zendeskChat}`,
                component: ReturnRepairChat
            },
            {
                path: "/terms-of-use",
                exact: true,
                name: "terms-of-use",
                component: TermsOfUse
            },
            {
                path: "/faq",
                exact: true,
                name: "faq",
                component: Faq,
                init: "./ae/faq.mobile"
            },
            {
                path: "/help-center",
                exact: true,
                name: "helpCenter",
                component: HelpCenter,
                init: "./ae/faq.mobile"
            },
            {
                path: "/help-center-desktop",
                exact: true,
                name: "helpCenter",
                component: HelpCenterPageDesktopModal,
                init: "./ae/faq.mobile"
            },
            {
                path: "/sitemap",
                exact: true,
                name: "sitemap",
                component: SiteMap,
                init: "./ae/sitemap.mobile"
            },
            {
                path: "/profile",
                exact: true,
                name: "profile",
                component: Profile
            },
            {
                path: "/upload",
                exact: true,
                name: "salesforce-upload",
                component: SalesforceUploads
            },
            {
                path: "/location-capture",
                exact: true,
                name: "loaction-capture",
                component: LocationCapture
            },
            {
                // homepage for seller
                path: SELLER_HOME_ROUTE,
                exact: true,
                isUsedCarRoute: false,
                name: "ae.sell-online",
                component: SellOnline,
                init: "./ae/seller-home.mobile"
            },
            {
                // homepage for bank validation report
                path: `/${ROUTE_PATHS.bankValuationCertificate}`,
                exact: true,
                isUsedCarRoute: false,
                name: `ae.${ROUTE_PATHS.bankValuationCertificate}`,
                component: BankValuationCertificate,
                init: "./ae/bank-valuation-report"
            },
            {
                // homepage for bank validation report
                path: `/${ROUTE_PATHS.bankValuationCertificate}-desktop`,
                exact: true,
                isUsedCarRoute: false,
                name: `ae.${ROUTE_PATHS.bankValuationCertificate}`,
                component: BankValuationCertificateDesktopFlow,
                init: "./ae/bank-valuation-report"
            },
            {
                // car evalution and appointment booking for bank valuation certificate flow
                path: `/${ROUTE_PATHS.bankValuationCertificateCarEvalution}/:stepid?`,
                exact: true,
                component: BankValuationCertificateCarEvaulation,
                isUsedCarRoute: false,
                name: `ae.${ROUTE_PATHS.bankValuationCertificateCarEvalution}`
            },
            {
                // order summary page for bank valuation certificate flow
                path: `/${ROUTE_PATHS.bankValuationCertificateOrderSummary}/:orderId`,
                exact: true,
                component: BankValuationCertificateOrderSummary,
                isUsedCarRoute: false,
                name: `ae.${ROUTE_PATHS.bankValuationCertificateOrderSummary}`
            },
            {
                // car inspection file upload page for bank valuation certificate flow
                path: `/${ROUTE_PATHS.bankValuationCertificateFileUpload}/:orderId`,
                exact: true,
                component: BvcFileUpload,
                isUsedCarRoute: false,
                name: `ae.${ROUTE_PATHS.bankValuationCertificateFileUpload}`
            },
            {
                path: "/book-appointment/:stepid",
                exact: true,
                isUsedCarRoute: false,
                name: "ae.book-appointment",
                component: SellCarBooking
            },
            {
                // seller inspection reval flow
                path: "/seller/revaluation/:appointmentId",
                exact: true,
                isUsedCarRoute: false,
                name: "ae.seller-revaluation",
                component: SellerRevaluation
            },
            {
                path: "/sell-used-car-:city",
                exact: true,
                isUsedCarRoute: false,
                name: "ae.sell-online",
                component: SellCarDetails,
                init: "./ae/seller-home.mobile"
            },
            {
                path: `/${ROUTE_PATHS.paymentMethod}/:appId`,
                exact: true,
                isUsedCarRoute: true,
                name: "payment-method",
                component: PaymentMethod,
                init: "./ae/postbc.mobile"
            },
            {
                path: "/modal/:modalType",
                exact: true,
                name: "modal-page",
                component: ModalPage
            },
            {
                path: "/payment-status",
                exact: true,
                name: "payment-status",
                isUsedCarRoute: true,
                component: PaymentStatus
            },
            {
                path: "/seller/post-price-acceptance/:stepId",
                exact: true,
                name: "ae.post-price-acceptance",
                isUsedCarRoute: false,
                component: SellerPostPriceAcceptance
            },
            {
                path: `${SELLER_HOME_ROUTE}nps`,
                exact: true,
                name: "ae.nps",
                isUsedCarRoute: false,
                component: SellerNps
            },
            {
                path: `/${ROUTE_PATHS.viewAllTickets}/:orderId/:appId`,
                exact: true,
                name: `ae-${ROUTE_PATHS.viewAllTickets}`,
                isUsedCarRoute: true,
                component: ViewAllTickets
            },
            {
                path: `/${ROUTE_PATHS.aiRecommendedCars}`,
                exact: true,
                name: `ae.${ROUTE_PATHS.aiRecommendedCars}`,
                isUsedCarRoute: true,
                component: AiRecommendedCars
            },
            {
                // C2C Market place is also called Car24 Direct so the names...
                path: "/seller/marketplace",
                exact: false,
                name: "ae.market-place",
                isUsedCarRoute: false,
                component: C2CDirectHome
            },
            {
                path: `/seller-center/:stepId?`,
                exact: false,
                name: "ae.seller-center",
                isUsedCarRoute: false,
                component: SellerCenter
            },
            {
                path: `/${ROUTE_PATHS.carCare}`,
                exact: false,
                name: `ae.${ROUTE_PATHS.carCare}`,
                isUsedCarRoute: false,
                component: CarCare,
                init: "./ae/car-care.mobile"
            },
            // KEEP SLUG AT THE LAST OF THIS FILE
            {
                path: "/:slug1?/:slug2?/:slug3?/:slug4?",
                exact: true,
                name: "ae.home",
                component: NotFound,
                init: "./ae/not-found"
            },
            {
                path: "*",
                exact: true,
                name: "ae.not-found",
                component: NotFound
                // component: () => <div>Mobile - 404</div>
                // init: "./home.mobile"
            }
        ]
    }
];

export { routes };
