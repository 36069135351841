import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    API_REQUEST
    API_RESPONSE
    UPDATE_GS_OFFER
    SET_PRICE_DETAILS
    SET_INVENTORY_DATA
    SET_SUCCESS_FEE
    SET_SELECTED_CARD
    SET_BI_DATA
    
`,
    {
        prefix: "c2csellercenter/"
    }
);
