import React, { useRef } from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";

const InputTextarea = ({
    placeholder = "Start typing...",
    inputRef,
    onChange = () => { },
    ...rest
}) => {
    const tempRef = useRef();
    const textAreaRef = inputRef || tempRef;

    const onChangeHandler = (e) => {
        if (textAreaRef.current.scrollTop !== 0) {
            textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
        }
        onChange(e);
    };

    return (
        <div className="customTextArea" styleName={"styles.inputShift"}>
            <textarea
                id= "textarea"
                ref={textAreaRef}
                className="form-control"
                placeholder={placeholder}
                onChange={onChangeHandler}
                {...rest}
            />
        </div>
    );
};

InputTextarea.propTypes = {
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    inputRef: PropTypes.object
};

export default InputTextarea;
