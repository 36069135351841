import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    FETCH_INSPECTION_REQUEST
    FETCH_INSPECTION_SUCCESS
    FETCH_INSPECTION_FAILURE

    UPDATE_INSPECTION_EVALUATION_REQUEST
    UPDATE_INSPECTION_EVALUATION_SUCCESS
    UPDATE_INSPECTION_EVALUATION_FAILURE

    SUBMIT_DISPUTES_TO_INSPECTION_REQUEST
    SUBMIT_DISPUTES_TO_INSPECTION_SUCCESS
    SUBMIT_DISPUTES_TO_INSPECTION_FAILURE

    UPDATE_INSPECTION_EVALUATION_DISPUTES
    SET_FEEDBACK_REASONS_LIST
`,
    {
        prefix: "sellerrevaluation/"
    }
);
