import { NUMBER } from "../../../constants/app-constants";

export const STEP_KEY = {
    PERSONAL: "PERSONAL",
    DOCUMENT: "DOCUMENT",
    EMPLOYMENT: "EMPLOYMENT",
    INCOME: "INCOME",
    CONTACT: "CONTACT"
};

export const STEP_MAP = {
    [STEP_KEY.PERSONAL]: NUMBER.ZERO,
    [STEP_KEY.DOCUMENT]: NUMBER.ONE,
    [STEP_KEY.EMPLOYMENT]: NUMBER.TWO,
    [STEP_KEY.INCOME]: NUMBER.THREE,
    [STEP_KEY.CONTACT]: NUMBER.FOUR
};

export const STEP_FLOW = [STEP_KEY.PERSONAL, STEP_KEY.DOCUMENT, STEP_KEY.EMPLOYMENT, STEP_KEY.INCOME, STEP_KEY.CONTACT];

export const HEADER_MAP = {
    [STEP_KEY.PERSONAL]: "Personal Details",
    [STEP_KEY.DOCUMENT]: "Document Details",
    [STEP_KEY.EMPLOYMENT]: "Employment Details",
    [STEP_KEY.INCOME]: "Income Details",
    [STEP_KEY.CONTACT]: "Contact Details"
};

export const UAE = "AE";
