/* eslint-disable complexity */
import { checkDateWithinInterval } from "../../../utils/helpers/check-date-within-interval";
import { createReducer } from "../../../utils/redux/create-reducer";
import { bottomNavDefaultConfig, superAppDefaultConfig } from "./constants";
import Types from "./types";

const COUPON_INITIAL_STATE = {
    isCouponListLoading: false,
    isCouponListFetched: false,
    couponList: null
};

export const INITIAL_STATE = {
    cityList: [],
    platform: "mobile",
    reservationDays: 0,
    reservationHours: 0,
    bookingAmount: 0,
    map: "googlemap",
    isFetched: false,
    userVariant: null,
    activeBookingCount: 0,
    bookingAllowed: true,
    couponData: COUPON_INITIAL_STATE,
    activeSaleConfig: {},
    seoContent: "",
    superAppConfig: superAppDefaultConfig,
    ratingInfo: {},
    bottomNavConfig: bottomNavDefaultConfig
};

export const fetchCityListSuccess = (state = INITIAL_STATE, { cityList }) => {
    return { ...state, cityList };
};

export const fetchConfigSuccess = (state = INITIAL_STATE, { configData, cityList, seoContent, ratingInfo, footerSection, budgetSection, superAppConfig, bottomNavConfig }) => {
    let saleDetails = configData?.saleConfig?.saleData;
    if (saleDetails && saleDetails?.active) {
        const {start, end} = saleDetails || {};
        saleDetails = {...saleDetails, ...{ active: checkDateWithinInterval(start, end) }};
    }
    return {
        ...state,
        cityList,
        ...configData,
        activeSaleConfig: saleDetails,
        isFetched: true,
        seoContent: seoContent || state.seoContent || "",
        superAppConfig: superAppConfig || state.superAppConfig || {},
        ratingInfo: ratingInfo || state.ratingInfo || null,
        footerSection: state?.footerSection || footerSection,
        budgetSection: state?.budgetSection || budgetSection,
        bottomNavConfig: bottomNavConfig || state.bottomNavConfig || {}
    };
};

export const fetchRatingInfoSuccess = (state = INITIAL_STATE, { ratingInfo }) => {
    return {
        ...state,
        ratingInfo
    };
};

export const setBookingCount = (state = INITIAL_STATE, {data}) => {
    return {
        ...state,
        ...data
    };
};

export const updateSaleData = (state = INITIAL_STATE, {data}) => {
    return {
        ...state,
        ...data
    };
};

// COUPON LIST
const fetchCouponListInit = (state = INITIAL_STATE) => {
    return {
        ...state,
        couponData: {
            ...state.couponData,
            isCouponListLoading: true,
            isCouponListFetched: false,
            couponList: null
        }
    };
};

const fetchCouponListSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        couponData: {
            ...state.couponData,
            isCouponListLoading: false,
            isCouponListFetched: true,
            couponList: data
        }
    };
};

const fetchCouponListFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        couponData: {
            ...state.couponData,
            isCouponListLoading: false,
            isCouponListFetched: true,
            couponList: null
        }
    };
};

export const updateSeoData = (state = INITIAL_STATE, {data}) => {
    return {
        ...state,
        ...data
    };
};

export const updateSuperAppConfig = (state = INITIAL_STATE, {data}) => {
    return {
        ...state,
        ...{superAppConfig: data}
    };
};

export const updateBottomNavConfig = (state = INITIAL_STATE, {data}) => {
    return {
        ...state,
        ...{bottomNavConfig: data}
    };
};

export const HANDLERS = {
    [Types.FETCH_CITY_LIST_SUCCESS]: fetchCityListSuccess,
    [Types.FETCH_CONFIG_SUCCESS]: fetchConfigSuccess,
    [Types.FETCH_BOOKING_COUNT]: setBookingCount,
    [Types.UPDATE_SALE_DATA]: updateSaleData,

    // COUPON LIST
    [Types.FETCH_COUPON_LIST_INIT]: fetchCouponListInit,
    [Types.FETCH_COUPON_LIST_SUCCESS]: fetchCouponListSuccess,
    [Types.FETCH_COUPON_LIST_FAILURE]: fetchCouponListFailure,

    //SEO DATA
    [Types.UPDATE_SEO_DATA]: updateSeoData,

    //SUPER APP CONFIG
    [Types.UPDATE_SUPER_APP_CONFIG]: updateSuperAppConfig,
    [Types.FETCH_RATINGS]: fetchRatingInfoSuccess,

    // BOTTOM NAV CONFIG
    [Types.UPDATE_BOTTOM_NAV_CONFIG]: updateBottomNavConfig

};

export default createReducer(INITIAL_STATE, HANDLERS);
