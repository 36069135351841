import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
`
    UPDATE_STEP
    UPDATE_USER_DETAILS

    FETCH_LOAN_PRE_APPROVAL_INIT
    FETCH_LOAN_PRE_APPROVAL_SUCCESS
    FETCH_LOAN_PRE_APPROVAL_FAILURE

    FETCH_LOAN_PRE_APPROVAL_USER_SELECTION
`,
{
    prefix: "enbdpreapproval/"
}
);
