//
// This is the client side entry point for the React app.
//

import React from "react";
import "./wdyr";
import { render, hydrate } from "react-dom";
import { routes } from "./routes.ae.mobile";
import { BrowserRouter } from "react-router-dom";
import { applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import { renderRoutes } from "react-router-config";
import thunk from "redux-thunk";
import { reducerManager } from "./reducer-manager";
import baseReducer from "./reducers/base.mobile";

import { PersistGate } from "redux-persist/integration/react";
import { loadableReady } from "@loadable/component";
import { migrationsMobile } from "./migration.mobile";
import { initializeSentry, sentryReduxEnhancer } from "../client/utils/sentry/init";
import { HelmetProvider } from "react-helmet-async";

initializeSentry();

const persistVerion = 4;
const persistKey = "rootMobileAE";
const whitelistedKeys = [
    "carEvaluation",
    "tdSchedule",
    "deliverySchedule",
    // "cities",
    "loanEligibility",
    "bvcCarEvaluation",
    "subscriptionVehicleDetails"
];

// const persistedReducer = persistReducer(persistConfig, rootReducer);
//
const middlewares = [thunk];
const DEBUG = process.env.NODE_ENV === "development";
const composeEnhancers =
    typeof window === "object" &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
        DEBUG ?
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
            // Specify extension’s options like name
            // actionsBlacklist, actionsCreators, serialize...
        }) : compose;
const enhancer = composeEnhancers(applyMiddleware(...middlewares), sentryReduxEnhancer);

//
// Redux configure store with Hot Module Reload
//
// const configureStore = initialState => {
//     const store = createStore(persistedReducer, initialState, enhancer);
//     const persistor = persistStore(store);

//     if (module.hot) {
//         module.hot.accept("./reducers/index.ae.mobile", () => {
//             const nextRootReducer = require("./reducers/index.ae.mobile").default;
//             store.replaceReducer(nextRootReducer);
//         });
//     }

//     return { store, persistor };
// };

// const { store, persistor } = configureStore(window.__PRELOADED_STATE__);

const { store, persistor } = reducerManager.configureStore({
    baseReducer,
    initialState: window.__PRELOADED_STATE__,
    enhancer,
    persistVerion,
    persistKey,
    whitelistedKeys,
    migration: migrationsMobile
});

const start = App => {
    const jsContent = document.querySelector(".js-content");
    const reactStart = window.__PRELOADED_STATE__ && jsContent.innerHTML ? hydrate : render;
    loadableReady(() =>
        reactStart(
            <HelmetProvider>
                <Provider store={store}>
                    <PersistGate loading={null} persistor={persistor}>
                    {() => (
                        <BrowserRouter>
                            <App />
                        </BrowserRouter>
                    )}
                    </PersistGate>
                </Provider>
            </HelmetProvider>,
            jsContent
        ));
};

// window.webappStart = () => start(() => renderRoutes(routes));
start(() => renderRoutes(routes));

//
// Hot Module Reload setup
//
if (module.hot) {
    module.hot.accept("./routes.ae.mobile", () => {
        const r = require("./routes.ae.mobile");
        start(() => renderRoutes(r.routes));
    });
}

export { store };
