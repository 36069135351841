import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";

export const INITIAL_STATE = {
    selectedStep: null,
    selectedPackage: null
};

const apiRequest = (state = INITIAL_STATE) => {
    return { ...state, isLoading: true };
};

const apiResponse = (state = INITIAL_STATE) => {
    return { ...state, isLoading: false };
};

const setSelectedStep = (state = INITIAL_STATE, {selectedStep}) => {
    return { ...state, selectedStep };
};
const setSelectedPackage = (state = INITIAL_STATE, {selectedPackage}) => {
    return { ...state, selectedPackage };
};

const setListPackages = (state = INITIAL_STATE, {data}) => {
    return { ...state, listPackages: {...data}};
};

export const HANDLERS = {
    [Types.API_REQUEST]: apiRequest,
    [Types.API_RESPONSE]: apiResponse,
    [Types.SET_SELECTED_STEP]: setSelectedStep,
    [Types.SET_SELECTED_PACKAGE]: setSelectedPackage,
    [Types.SET_LIST_PACKAGES]: setListPackages

};

export default createReducer(INITIAL_STATE, HANDLERS);
