import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";

export const INITIAL_STATE = {
    recommendedCars: [],
    config: {},
    isLoadingRecommendedCars: true
};
export const initAiRecommendedCars = (state = INITIAL_STATE) => {
    return { ...state, isLoadingRecommendedCars: true};
};
export const setAiRecommendedApiError = (state = INITIAL_STATE, {data}) => {
    return { ...state, recommendedCars: [], isLoadingRecommendedCars: false, ...(data || {})};
};
export const setAiRecommendedCars = (state = INITIAL_STATE, { data, config }) => {
    return { ...state, recommendedCars: data, config, isLoadingRecommendedCars: false};
};

export const HANDLERS = {
    [Types.AI_RECOMMENDED_CARS]: setAiRecommendedCars,
    [Types.AI_RECOMMENDED_API_ERROR]: setAiRecommendedApiError,
    [Types.INIT]: initAiRecommendedCars
};

export default createReducer(INITIAL_STATE, HANDLERS);
