import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
        TOGGLE_LOCATION_PICKER
    `,
    {
        prefix: "locationpickerpopup/"
    }
);
