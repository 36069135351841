import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    SET_NPS_QUESTIONS
    SET_DISPLAY_QUESTIONS
    API_REQUEST
    API_RESPONSE
    SET_RATING
`,
    {
        prefix: "sellonlinenps/"
    }
);
