import { createReducer } from "../../../utils/redux/create-reducer";
import { CAR_LIST_EVENT_NAMES, PAYMENT_OPTIONS } from "../../../constants/app-constants";
import { GALLERY_TAB_TYPE } from "./constant";
import Types from "./types";

export const INITIAL_STATE = {
    content: {
        city: "",
        price: 0,
        emiDetails: {}
    },
    footerMetaContent: [],
    inspectionReport: null,
    error: null,
    isFetching: null,
    isSSR: false,
    selectedImage: null,
    impressionSource: CAR_LIST_EVENT_NAMES.DETAILS,
    isFetchingSimilarToOfferCars: false,
    similarToOfferCars: [],
    activeSimilarToOfferCarIndex: null,
    activeSimilarToOfferCarError: null,
    similarCarsPage: 0,
    similarCarsSize: 5,
    appendSimilarCars: false,
    offerScore: {},
    offerScoreError: null,
    isMakeOfferPaywallVisible: false,
    isB2CCity: false,
    isReportDownloaded: false,
    paymentOption: PAYMENT_OPTIONS.FINANCE,
    carLocationsError: null,
    carLocations: {
        appliedPincode: ""
    },
    carLocationsFetched: false,
    galleryProps: {
        isOpen: false,
        activeTab: GALLERY_TAB_TYPE.EXTERIOR,
        isSpinCarLoaded: false,
        isSpinCarLoading: false,
        selectedImageIndex: 0
    },
    similarCars: [],
    internalLinks: [],
    similarVehicleCount: null,
    isSimilarCarsFetching: false,
    isSimilarCarsError: null,
    config: {},
    similarCarsComparison: {
        isSSR: false,
        selectedCars: [],
        compareData: {
            details: {},
            metadata: {}
        }
    },
    reloadCouponList: false,
    priceBenefit: {},
    ispriceBenefitLoading: false,
    similarCarsForModal: {},
    isSimilarCarsForModalLoading: false,
    isCouponInfoFetched: false,
    isShowStickyTabs: false,
    showPriceBenifits: false
};

export const fetchCarDetails = (state = INITIAL_STATE) => {
    return { ...state, isFetching: true, content: {
        detail: {
            emiDetails: {

            }
        },
        config: {
            imageHost: ""
        }
    }, inspectionReport: null };
};

export const fetchCarDetailsSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        content: data.detail,
        config: data.config,
        footerMetaContent: data.footerMetaContent,
        inspectionReport: data.detail.inspectionReport,
        isFetching: false,
        paymentOption: PAYMENT_OPTIONS.FINANCE,
        error: null
    };
};

export const fetchCarDetailsFailure = (state = INITIAL_STATE, { error }) => {
    return { ...state, error, isFetching: false};
};

export const initFetchInspectionReport = (state = INITIAL_STATE) => {
    return {
        ...state,
        inspectionReport: null,
        inspectionReportError: null,
        isFetchingInspectionReport: true
    };
};

export const fetchInspectionReportSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        inspectionReport: data,
        inspectionReportError: null,
        isFetchingInspectionReport: false
    };
};

export const fetchInspectionReportFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        inspectionReport: null,
        inspectionReportError: error,
        isFetchingInspectionReport: false
    };
};

export const updateCarDetailsSSRStatus = (state = INITIAL_STATE, { isSSR }) => {
    return { ...state, isSSR };
};

export const updateSelectedImage = (state = INITIAL_STATE, { selectedImage }) => {
    return { ...state, selectedImage };
};

export const updateImpressionSource = (state = INITIAL_STATE, { impressionSource }) => {
    return { ...state, impressionSource };
};

export const fetchSimilarOfferCars = (state = INITIAL_STATE, { similarCarsPage, similarCarsSize, appendSimilarCars }) => {
    return {
        ...state,
        isFetchingSimilarToOfferCars: true,
        similarToOfferCars: state.appendSimilarCars ? [...state.similarToOfferCars] : [],
        activeSimilarToOfferCarError: null,
        activeSimilarToOfferCarIndex: null,
        similarCarsPage,
        similarCarsSize,
        appendSimilarCars
    };
};

export const fetchSimilarToOfferCarsSuccess = (state = INITIAL_STATE, { data }) => {
    let similarToOfferCars = [];
    if (state.appendSimilarCars) {
        if (state.similarCarsPage === 0) {
            similarToOfferCars = [...data];
        } else {
            similarToOfferCars = [...state.similarToOfferCars, ...data];
        }
    } else {
        similarToOfferCars = data.slice(0, state.similarCarsSize);
    }
    return {
        ...state,
        similarToOfferCars,
        isFetchingSimilarToOfferCars: false,
        activeSimilarToOfferCarError: null,
        activeSimilarToOfferCarIndex: 0
    };
};

export const fetchSimilarToOfferCarsFailure = (state = INITIAL_STATE, { error }) => {
    return { ...state, activeSimilarToOfferCarError: error, isFetchingSimilarToOfferCars: false};
};

export const updateActiveSimilarToOfferCarIndex = (state = INITIAL_STATE, { activeSimilarToOfferCarIndex }) => {
    return { ...state, activeSimilarToOfferCarIndex };
};

export const removeSimilarToOfferCar = (state = INITIAL_STATE, { appId }) => {
    const newIndex = (state.activeSimilarToOfferCarIndex === null ? null : state.activeSimilarToOfferCarIndex) - 1;
    const similarCars = (state.similarToOfferCars || []).filter(car => car.carId !== appId);
    return {
        ...state,
        similarToOfferCars: similarCars,
        // eslint-disable-next-line no-nested-ternary
        activeSimilarToOfferCarIndex: newIndex >= 0 ? newIndex : (similarCars.length ? 0 : null)
    };
};

export const updateSpincarModalStatus = (state = INITIAL_STATE, {isSpinCarModalOpen}) => {
    return {...state, isSpinCarModalOpen};
};

export const setGsGalleryData = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        galleryProps: {
            ...state.galleryProps,
            ...data
        }
    };
};

// Similar Cars
export const fetchSimilarCars = (state = INITIAL_STATE) => {
    return {
        ...state,
        isSimilarCarsFetching: true,
        similarCars: []
    };
};
export const fetchSimilarCarsSuccess = (state = INITIAL_STATE, { data }) => {

    if (data?.internalDetails && data?.similarVehicleCount) {
        return {
            ...state,
            similarCars: data.similarVehicles,
            internalLinks: data.internalDetails,
            isSimilarCarsFetching: false,
            isSimilarCarsError: null,
            similarVehicleCount: data.similarVehicleCount,
            config: data?.config
        };
    } else {
        return {
            ...state,
            similarCars: data.similarVehicles,
            isSimilarCarsFetching: false,
            isSimilarCarsError: null,
            config: data?.config
        };
    }

};
export const fetchSimilarCarsFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        isSimilarCarsError: error,
        isSimilarCarsFetching: false
    };
};

// Similar Cars For Modal
export const fetchSimilarCarsForModalInit = (state = INITIAL_STATE) => {
    return {
        ...state,
        similarCarsForModal: {},
        isSimilarCarsForModalLoading: true
    };
};
export const fetchSimilarCarsForModalSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        similarCarsForModal: data,
        isSimilarCarsForModalLoading: false
    };
};
export const fetchSimilarCarsForModalFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        similarCarsForModal: {},
        isSimilarCarsForModalLoading: false
    };
};

// Price Benefits For Modal
export const fetchPriceBenefitInit = (state = INITIAL_STATE) => {
    return {
        ...state,
        priceBenefit: {},
        isPriceBenefitLoading: true
    };
};
export const fetchPriceBenefitSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        priceBenefit: data,
        isPriceBenefitLoading: false
    };
};
export const fetchPriceBenefitFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        priceBenefit: {},
        isPriceBenefitLoading: false
    };
};

// Similar Cars Compare
export const fetchSimilarCarsCompare = (state = INITIAL_STATE) => {
    return { ...state, isSimilarCarsFetching: true, similarCarsComparison: {
        ...state.similarCarsComparison
    } };
};
export const fetchSimilarCarsCompareSuccess = (state = INITIAL_STATE, { data, isSSR = false }) => {
    const { comparisonDetails, internalDetails = [] } = data || {};
    const { details, metadata } = comparisonDetails || {};
    return {
        ...state,
        config: data?.config,
        internalLinks: internalDetails || state.internalLinks || [],
        similarCarsComparison: {
            ...state.similarCarsComparison,
            isSSR,
            selectedCars: details ? Object.values(details).map(({ appointmentId, make, model, year, variant, mainImage }) => ({ appointmentId, make, model, year, variant, mainImage})) : [],
            compareData: {
                ...state.similarCarsComparison.compareData,
                details,
                metadata
            }
        }
    };
};
export const fetchSimilarCarsCompareFailure = (state = INITIAL_STATE) => {
    return { ...state, isSimilarCarsFetching: false };
};

export const sendReportNotificationSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        isReportDownloaded: data
    };
};

export const refreshCouponList = (state = INITIAL_STATE) => {
    return { ...state, reloadCouponList: true };
};

// Hand Picked cars
export const fetchHandPickedCarsInit = (state = INITIAL_STATE) => {
    return {
        ...state,
        handPickedCars: {},
        isHandPickedCarsLoading: true
    };
};
export const fetchHandPickedCarsSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        handPickedCars: data,
        isHandPickedCarsLoading: false
    };
};
export const fetchHandPickedCarsFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        handPickedCars: {},
        isHandPickedCarsLoading: false
    };
};

export const couponInfoFetched = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        isCouponInfoFetched: data
    };
};

export const toggleIsShowStickyTabs = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        isShowStickyTabs: data
    };
};

export const togglePriceBenifits = (state = INITIAL_STATE) => {
    return {
        ...state,
        showPriceBenifits: !state.showPriceBenifits
    };
};

export const HANDLERS = {
    [Types.FETCH_CAR_DETAILS]: fetchCarDetails,
    [Types.FETCH_CAR_DETAILS_SUCCESS]: fetchCarDetailsSuccess,
    [Types.FETCH_CAR_DETAILS_FAILURE]: fetchCarDetailsFailure,
    [Types.UPDATE_SSR_STATUS]: updateCarDetailsSSRStatus,

    // Similar Cars
    [Types.FETCH_SIMILAR_CARS]: fetchSimilarCars,
    [Types.FETCH_SIMILAR_CARS_SUCCESS]: fetchSimilarCarsSuccess,
    [Types.FETCH_SIMILAR_CARS_FAILURE]: fetchSimilarCarsFailure,

    // Similar Cars For Modal
    [Types.FETCH_SIMILAR_CARS_FOR_MODAL_INIT]: fetchSimilarCarsForModalInit,
    [Types.FETCH_SIMILAR_CARS_FOR_MODAL_SUCCESS]: fetchSimilarCarsForModalSuccess,
    [Types.FETCH_SIMILAR_CARS_FOR_MODAL_FAILURE]: fetchSimilarCarsForModalFailure,

    // Price Benefit
    [Types.FETCH_PRICE_BENEFIT_INIT]: fetchPriceBenefitInit,
    [Types.FETCH_PRICE_BENEFIT_SUCCESS]: fetchPriceBenefitSuccess,
    [Types.FETCH_PRICE_BENEFIT_FAILURE]: fetchPriceBenefitFailure,

    // Similar Cars Compare
    [Types.FETCH_SIMILAR_CARS_COMPARE]: fetchSimilarCarsCompare,
    [Types.FETCH_SIMILAR_CARS_COMPARE_SUCCESS]: fetchSimilarCarsCompareSuccess,
    [Types.FETCH_SIMILAR_CARS_COMPARE_FAILURE]: fetchSimilarCarsCompareFailure,

    [Types.SET_GS_GALLERY_DATA]: setGsGalleryData,
    [Types.SEND_REPORT_NOTIFICATION_SUCCESS]: sendReportNotificationSuccess,
    [Types.REFRESH_COUPON_LIST]: refreshCouponList,

    // Hand Picked Cars For Modal
    [Types.FETCH_HAND_PICKED_CARS_INIT]: fetchHandPickedCarsInit,
    [Types.FETCH_HAND_PICKED_CARS_SUCCESS]: fetchHandPickedCarsSuccess,
    [Types.FETCH_HAND_PICKED_CARS_FAILURE]: fetchHandPickedCarsFailure,
    [Types.COUPON_INFO_FETCHED]: couponInfoFetched,
    [Types.TOGGLE_STICKY_TABS]: toggleIsShowStickyTabs,
    [Types.TOGGLE_PRICE_BENIFITS]: togglePriceBenifits
};

export default createReducer(INITIAL_STATE, HANDLERS);
