import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";

export const INITIAL_STATE = {
    isLoading: false,
    make: {},
    model: {},
    isUploading: false,
    isUpdating: false,
    seller: {
        bankDetails: {
            name: "",
            bankName: "",
            iban: "",
            ibanConfirm: "",
            accountNumber: "",
            swiftCode: ""
        },
        paymentMode: ""
    },
    rtaBooking: {
        address: {}
    }
};

export const fetchPostPriceInfoRequest = (state = INITIAL_STATE) => {
    return {
        ...state,
        isLoading: true
    };
};

export const fetchPostPriceInfoSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        ...data,
        isLoading: false
    };
};

export const fetchPostPriceInfoFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        isLoading: false,
        error
    };
};

export const updateUploadStatus = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        isUploading: data
    };
};

export const setUpdateStatus = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        isUpdating: data
    };
};

export const fillBankDetails = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        seller: { ...state?.seller, bankDetails: data }
    };
};

export const updatePaymentMode = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        seller: { ...state?.seller, paymentMode: data }
    };
};

export const updateSellerName = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        seller: { ...state?.seller, contactPerson: { ...state?.seller?.contactPerson, name: data } }
    };
};

export const updateRTADetails = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        rtaBooking: { ...state.rtaBooking, ...data }
    };
};

export const HANDLERS = {
    [Types.FETCH_POST_PRICE_REQUEST]: fetchPostPriceInfoRequest,
    [Types.FETCH_POST_PRICE_SUCCESS]: fetchPostPriceInfoSuccess,
    [Types.FETCH_POST_PRICE_FAILURE]: fetchPostPriceInfoFailure,
    [Types.SET_UPLOADING_STATUS]: updateUploadStatus,
    [Types.SET_IS_UPDATING]: setUpdateStatus,
    [Types.FILL_BANK_DETAILS]: fillBankDetails,
    [Types.SET_PAYMENT_MODE]: updatePaymentMode,
    [Types.UPDATE_RTA_DETAILS]: updateRTADetails,
    [Types.UPDATE_SELLER_NAME]: updateSellerName
};

export default createReducer(INITIAL_STATE, HANDLERS);
