/* eslint-disable max-statements */
/* eslint-disable no-unused-expressions */
/* eslint-disable complexity */
import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import loadable from "@loadable/component";
import PropTypes from "prop-types";
import qs from "querystring";
import "intersection-observer";
import smoothscroll from "smoothscroll-polyfill";
// CSS Files
import "../../../styles/slick-reset-mobile.css"; // slick slider
import "../../../styles/slick-theme.css"; // slick slider
import "../../../styles/slick.css"; // slick slider
import "../../../fonts/fonts.css"; // site fonts
import "../../../styles/reset.ae.mobile.css"; // Reset and global styles

// Utilities
import { updateWebviewStatus } from "../../ae.configuration/actions";
import { getItem, setItem } from "../../../utils/helpers/storage-crud";
import { setChatIconProperty } from "../chat-icon/actions";

// Components
import ErrorHandler from "../../shared/with-error-handler";
const Error = loadable(() => import("../../shared/error"), { ssr: false });
const Header = loadable(() => import("../header"), { ssr: true });
const SuperAppHpHeader = loadable(() => import("../super-app-hp-header"), { ssr: true });
const BackToTop = loadable(() => import("../back-to-top"), { ssr: false });
const LoginToUnlockCouponNudge = loadable(() => import("../login-to-unlock-coupon-nudge"), { ssr: true });
const ChatIcon = loadable(() => import("../chat-icon"));
const AiCarFinderSearchModal = loadable(() => import("../ai-car-finder-search-modal"));
const AiBotIcon = loadable(() => import("../ai-bot-icon"), { ssr: true });
const BottomNavigation = loadable(() => import("../bottom-navigation"), { ssr: true });
const WishlistNudge = loadable(() => import("../wishlist-nudge"));
const SaleLiveNotifier = loadable(() => import("../sale-live-notifier"), { ssr: true });
const SaleLiveNotifierPopup = loadable(() => import("../sale-live-notifier-popup"), { ssr: false });

// Constants
import { ROUTE_NAME } from "../../../constants/routes-constants";
import { COOKIE_CONSTANTS, NUMBER } from "../../../constants/app-constants";
import FooterV2 from "../footerv2/index";
import NoInternet from "../no-internet";
import parseCookie from "../../../utils/helpers/parse-cookie";
import saveCookie from "../../../utils/helpers/save-cookie";

// eslint-disable-next-line max-params
export default function withAELayout(
    WrappedComponent,
    header = true,
    footer = true,
    options = {}
) {
    const { headerProps } = options;
    const Layout = (props) => {
        const { route = {},
            showBackToTop,
            backToTopRoutes,
            toast, iconPropertiesBottomNav,
            openAICarFinderModal,
            activeSaleConfig,
            isLoggedIn,
            //bookings,
            // fetchingSnackCards,
            // snackCardsFetched,
            coupon,
            showDownloadNudge
        } = props || {};
        const [toastMessage, setToastMessage] = useState(null);
        const [showWishlistNudge, setShowWishlistNudge] = useState(false);
        const [showSaleBottomNudge, setShowSaleBottomNudge] = useState(false);
        const [showSaleNotifierPopup, setShowSaleNotifierPopup] = useState(false);

        const { active: isSaleActive = false } = activeSaleConfig || {};

        // const { cars: snackCards = [] } = bookings || {};
        //const isSnackBarShown = (!fetchingSnackCards && snackCardsFetched && snackCards?.length > NUMBER.ZERO) || (isSaleActive && !isLoggedIn && parseCookie(COOKIE_CONSTANTS.BOTTOM_SALE_NUDGE_NOTIFIER_SHOWN));
        const { showCouponNudge } = coupon || {};

        useEffect(() => {
            if (toast?.text) {
                import("../../shared/toast-message").then((comp) => {
                    setToastMessage(<comp.default />);
                });
            }
        }, [toast]);

        useEffect(() => {
            smoothscroll.polyfill();
            if (getItem("isWebView")) {
                props.updateWebviewStatusConnect(true);
            } else {
                const params = qs.parse(window.location.search.substring(1));
                // params.view_source === "app" used for hiding header and footer when coming through FAQ page (mobile app)
                // params.utm_source === "more_tab_app" used for hiding header and footer when coming through mobile app
                const isWebView = params.utm_source === "more_tab_app" || params.view_source === "app";
                if (isWebView) {
                    props.updateWebviewStatusConnect(true);
                    setItem(true, "isWebView");
                }
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [props.isWebview]);

        useEffect(() => {
            if (route.name === ROUTE_NAME.DETAIL_PAGE) {
                setShowWishlistNudge(true);
            } else {
                setShowWishlistNudge(false);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [route.name]);

        useEffect(() => {
            const SaleLiveNotifierShownAlready = parseCookie(COOKIE_CONSTANTS.BOTTOM_SALE_NUDGE_NOTIFIER_SHOWN);

            if (isSaleActive && !SaleLiveNotifierShownAlready && !showDownloadNudge) {
                setShowSaleBottomNudge(true);
            }
        }, [isSaleActive, showDownloadNudge]);

        const onSaleBottomClose = ({ source = "closeButton" }) => {
            setShowSaleBottomNudge(false);
            saveCookie(COOKIE_CONSTANTS.BOTTOM_SALE_NUDGE_NOTIFIER_SHOWN, true);
            if (source === "closeButton") {
                setShowSaleNotifierPopup(true);
            }
        };

        const renderHeaderComponent = () => {
            if (route?.name === ROUTE_NAME.HOME || route?.name === ROUTE_NAME.LISTING) {
                return (<SuperAppHpHeader {...props} {...headerProps} />);
            }
            return (<Header {...props} {...headerProps} />);

        };
        const hasRevampedRoutes = [ROUTE_NAME.LISTING, ROUTE_NAME.HOME, ROUTE_NAME.DETAIL_PAGE].includes(route?.name);

        return props.isSSRError ? <Error /> :
            <Fragment>
                <ErrorHandler>
                    {header && !props.isWebview && renderHeaderComponent()}
                </ErrorHandler>
                <ErrorHandler>
                    {route?.name === ROUTE_NAME.HOME && <BottomNavigation routeName={route?.name} />}
                </ErrorHandler>
                {[ROUTE_NAME.HOME, ROUTE_NAME.LISTING].includes(route?.name) && showSaleBottomNudge &&
                    <ErrorHandler>
                        <SaleLiveNotifier onSaleBottomClose={onSaleBottomClose} />
                    </ErrorHandler>}
                <ErrorHandler><WrappedComponent {...props} /></ErrorHandler>
                <ErrorHandler>
                    {footer && !props.isWebview && <FooterV2 {...props} />}
                </ErrorHandler>
                <ErrorHandler>
                    {toastMessage && toastMessage}
                </ErrorHandler>
                {(backToTopRoutes.includes(route?.name) && showBackToTop) && (
                    <ErrorHandler>
                        <BackToTop {...props} />
                    </ErrorHandler>
                )}
                {iconPropertiesBottomNav[route?.name] && (![ROUTE_NAME.LISTING, ROUTE_NAME.SELL_ONLINE, ROUTE_NAME.EPIC_DROP_SALE].includes(route?.name)) ?
                    <ErrorHandler>
                        <ChatIcon {...{ ...props, isAnotherCTAVisible: (route.name === ROUTE_NAME.DETAIL_PAGE  || route.name === ROUTE_NAME.CAR_EVALUATION) }} />
                    </ErrorHandler> : <Fragment />
                }
                <ErrorHandler>
                    <NoInternet />
                    {(hasRevampedRoutes && !isLoggedIn && showCouponNudge) && <LoginToUnlockCouponNudge isDetailPage={route?.name === ROUTE_NAME.DETAIL_PAGE} />}
                </ErrorHandler>
                {[ROUTE_NAME.SIMILAR_CARS, ROUTE_NAME.PERSONALIZED_CARS, ROUTE_NAME.AI_RECOMMENDED_CARS].includes(route?.name) ?
                    <AiBotIcon {...props} bottom={NUMBER.HUNDRED_FIFTY} /> : <Fragment />
                }
                {openAICarFinderModal &&
                    <AiCarFinderSearchModal openAICarFinderModal={openAICarFinderModal} />
                }
                {showWishlistNudge && <WishlistNudge />}
                {showSaleNotifierPopup && <SaleLiveNotifierPopup onClose={() => setShowSaleNotifierPopup(false)} />}
            </Fragment>;
    };

    Layout.propTypes = {
        isWebview: PropTypes.bool,
        isSSRError: PropTypes.bool,
        updateWebviewStatusConnect: PropTypes.func,
        setChatIconPropertyConnect: PropTypes.func,
        route: PropTypes.any,
        iconProperties: PropTypes.object,
        iconPropertiesBottomNav: PropTypes.object,
        showBackToTop: PropTypes.bool,
        backToTopRoutes: PropTypes.array,
        openAICarFinderModal: PropTypes.bool,
        toast: PropTypes.object,
        wishlistedCarsList: PropTypes.array,
        showDownloadNudge: PropTypes.bool
    };

    Layout.defaultProps = {};

    const mapStateToProps = ({
        user: { isWebview, isSSRError, isLoggedIn, openAICarFinderModal, coupon, showDownloadNudge },
        chatIcon: { iconProperties, iconPropertiesBottomNav },
        backToTop: { show: showBackToTop, routes: backToTopRoutes },
        toast: { toast },
        myBookings: { bookings, isFetching: fetchingSnackCards, snackCardsFetched },
        config: { activeSaleConfig }
    }) => {
        return {
            isWebview,
            isSSRError,
            iconProperties,
            showBackToTop,
            backToTopRoutes,
            toast,
            iconPropertiesBottomNav,
            openAICarFinderModal,
            activeSaleConfig,
            isLoggedIn,
            bookings,
            fetchingSnackCards,
            snackCardsFetched,
            coupon,
            showDownloadNudge
        };
    };

    const mapDispatchToProps = (dispatch) => bindActionCreators({
        updateWebviewStatusConnect: updateWebviewStatus,
        setChatIconPropertyConnect: setChatIconProperty
    }, dispatch);

    return connect(mapStateToProps, mapDispatchToProps)(Layout);
}
