import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";

export const INITIAL_STATE = {
    make: {},
    model: {},
    year: "",
    variant: "",
    engineSize: "0.0",
    transmission: "",
    fuelType: "",
    noOfCylinders: 0,
    active: false,
    appointmentId: "",
    city: null,
    id: null,
    questions: {},
    showPrice: false,
    dsOfferedPrice: 0,
    status: null,
    vehicleType: "CAR",
    updatedAt: null,
    customer: {
        name: "",
        phone: "",
        whatsappConsent: true,
        whatsappConsentStatus: null
    },
    isGsEligible: false,
    isSoEligible: false,
    error: null,
    isLoading: false,
    isUpdating: false,
    locationName: "",
    dateTimeSlot: {},
    bookingDetail: {
        date: "",
        timeSlotBucket: "",
        startTime: "",
        endTime: "",
        reschedule: false,
        rescheduleCount: 0,
        address: {
            location: "",
            address1: "",
            address2: "",
            lat: 0,
            lng: 0,
            zipcode: ""
        },
        walkInAppointment: false
    },
    showAppointmentLocationPicker: false,
    isNotSure: false,
    carSelfInspection: null,
    sellerCarDetails: {},
    showOtpModal: false,
    nonEligibleReasons: [],
    ra: null,
    carConditionStepIndex: 0
};

const defaultCarDetails = {
    variant: "",
    engineSize: "0.0",
    transmission: "",
    fuelType: "",
    noOfCylinders: 0
};

export const setLeadBrand = (state = INITIAL_STATE, { data }) => {
    const { code, name } = data;
    let carEvaluationDetails = {};

    if (state?.make?.code !== code) {
        carEvaluationDetails = {
            model: {},
            year: "",
            ...defaultCarDetails,
            sellerCarDetails: { makeName: name }
        };
    } else {
        carEvaluationDetails = {
            ...state.sellerCarDetails, makeName: name
        };
    }
    return {
        ...state,
        ...carEvaluationDetails,
        make: { code, name }
    };
};

export const setLeadModel = (state = INITIAL_STATE, { data }) => {
    const { code, name } = data;
    let carEvaluationDetails = {};
    if (state?.model?.code !== code) {
        carEvaluationDetails = {
            year: "",
            ...defaultCarDetails,
            sellerCarDetails: { modelName: name, makeName: state?.make?.name }
        };
    } else {
        carEvaluationDetails = {
            ...state.sellerCarDetails, modelName: name
        };
    }
    return {
        ...state,
        ...carEvaluationDetails,
        model: { code, name }
    };
};

export const setLeadYear = (state = INITIAL_STATE, { year }) => {
    let carEvaluationDetails = {};
    if (year && +year.code !== +state?.year) {
        carEvaluationDetails = {
            ...defaultCarDetails,
            sellerCarDetails: { modelName: state?.model?.name, makeName: state?.make?.name, year: year?.name }
        };
    } else {
        carEvaluationDetails = {
            ...state.sellerCarDetails, year: year.name
        };
    }
    return {
        ...state,
        ...carEvaluationDetails,
        year: year.name
    };
};

export const setLeadData = (state = INITIAL_STATE, { key, value }) => {
    return {
        ...state,
        [key]: value,
        sellerCarDetails: {
            ...state.sellerCarDetails,
            [key]: value
        }
    };
};

export const setLeadDataByVariants = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        ...data
    };
};

export const setLeadCustomerData = (state = INITIAL_STATE, { customer }) => {
    return {
        ...state,
        customer
    };
};

export const setLeadBookingDetails = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        bookingDetail: data
    };
};
export const createLeadAppointmentRequest = (state = INITIAL_STATE) => {
    return {
        ...state,
        isUpdating: true
    };
};
export const createLeadAppointmentSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        ...data,
        isUpdating: false
    };
};
export const createLeadAppointmentFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        ...error,
        isUpdating: false
    };
};

export const fetchLeadAppointmentInfoRequest = (state = INITIAL_STATE) => {
    return {
        ...state,
        isLoading: true
    };
};

export const fetchLeadAppointmentInfoSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        ...data,
        error: null,
        isLoading: false
    };
};

export const fetchLeadAppointmentInfoFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        isLoading: false,
        error
    };
};

export const carSelfInspectionRequest = (state = INITIAL_STATE) => {
    return {
        ...state,
        isLoading: true
    };
};
export const carSelfInspectionSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        carSelfInspection: data ? { ...data } : null,
        isLoading: false
    };
};
export const carSelfInspectionFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        ...error,
        isLoading: false
    };
};

export const setSellerRA = (state = INITIAL_STATE, { data }) => {
    return { ...state, ra: data };
};

export const setCarConditionStepIndex = (state = INITIAL_STATE, { index }) => {
    return {
        ...state,
        carConditionStepIndex: index
    };
};

export const HANDLERS = {
    [Types.SET_EVALUATION_BRAND]: setLeadBrand,
    [Types.SET_EVALUATION_MODEL]: setLeadModel,
    [Types.SET_EVALUATION_YEAR]: setLeadYear,
    [Types.SET_EVALUATION_DATA]: setLeadData,
    [Types.SET_EVALUATION_DATA_BY_VARIANTS]: setLeadDataByVariants,
    [Types.SET_EVALUATION_CUSTOMER]: setLeadCustomerData,
    [Types.SET_BOOKING_DETAILS]: setLeadBookingDetails,

    [Types.FETCH_LEAD_APPOINTMENT_INFO_REQUEST]: fetchLeadAppointmentInfoRequest,
    [Types.FETCH_LEAD_APPOINTMENT_INFO_SUCCESS]: fetchLeadAppointmentInfoSuccess,
    [Types.FETCH_LEAD_APPOINTMENT_INFO_FAILURE]: fetchLeadAppointmentInfoFailure,

    [Types.CREATE_LEAD_APPOINTMENT_SUCCESS]: createLeadAppointmentSuccess,
    [Types.CREATE_LEAD_APPOINTMENT_REQUEST]: createLeadAppointmentRequest,
    [Types.CREATE_LEAD_APPOINTMENT_FAILURE]: createLeadAppointmentFailure,

    [Types.CAR_SELF_INSPECTION_REQUEST]: carSelfInspectionRequest,
    [Types.CAR_SELF_INSPECTION_SUCCESS]: carSelfInspectionSuccess,
    [Types.CAR_SELF_INSPECTION_FAILURE]: carSelfInspectionFailure,

    [Types.SET_SELLER_RA]: setSellerRA,
    [Types.SET_CAR_CONDITION_STEP_INDEX]: setCarConditionStepIndex
};

export default createReducer(INITIAL_STATE, HANDLERS);

