import { resettableReducer } from "../utils/redux/resettable-reducer";
import { reducerConstants } from "../constants/reducer-constant.js";
import userReducer from "../components/ae.configuration/reducers";
import carListReducer from "../components/ae.mobile/car-listing/reducers";
import carDetailsReducer from "../components/ae.mobile/car-details-v2/reducers";
import citiesReducer from "../components/ae.mobile/location-picker/reducers";
import filtersReducer from "../components/ae.mobile/filters/reducers";
import configReducer from "../components/ae.mobile/config-ssr/reducers";
import locationPickerReducer from "../components/ae.mobile/location-picker-popup/reducers";
import checkoutReducer from "../components/ae.mobile/checkout-info/reducers";
// import checkoutReducer from "../components/ae.mobile/checkout-info/reducers";
import emiDetailsReducer from "../components/ae.mobile/emi-details-section/reducers";
import experimentsReducer from "../components/shared/ab-experiment/reducers";
// import locationReducer from "../components/location/reducers";
import myBookings from "../components/ae.mobile/my-bookings/reducers";
import myWishlist from "../components/ae.mobile/my-wishlist/reducers";
// import tofService from "../components/ae.mobile/tof-service/reducers"; // moved to dynamic injection
// import tofServiceSchedule from "../components/ae.mobile/tof-service-schedule/reducers"; // moved to dynamic injection
import carLoan from "../components/ae.mobile/car-loan-home/reducers";
import seoCarDetails from "../components/ae.mobile/seo-car-details/reducers";
import toastMessageReducer from "../components/ae.mobile/toast-message/reducers";
// Uncomment to cause the issue
import backToTopReducer from "../components/ae.mobile/back-to-top/reducers";
import recentlyViewedCarsReducer from "../components/ae.mobile/recently-viewed-car-list/reducers";
import faqReducer from "../components/ae.mobile/faq/reducers";
import feedbackReducer from "../components/ae.mobile/feedback/reducers";
import userCfDetailsReducer from "../components/ae.mobile/financing-info/reducers";
import carCfDetailsReducer from "../components/ae.mobile/checkout-finance-info/reducers";
import chatIconReducer from "../components/ae.mobile/chat-icon/reducers";
import salesforceUploadsReducer from "../components/ae.mobile/salesforce-uploads/reducers";
import compareCars from "../components/ae.mobile/compare-widget/reducers";
import PaymentReducer from "../components/ae.mobile/noon-payment-status/reducers";
// import PostBcReducer from "../components/ae.mobile/post-booking-confirmation-v2/reducers";
// import PostBcVasReducer from "../components/ae.mobile/post-bc-vas/reducers";
// import tdScheduleReducer from "../components/ae.mobile/td-schedule/reducers";
// import c2cTdScheduleReducer from "../components/ae.mobile/td-schedule-c2c/reducers";
// import deliveryScheduleReducer from "../components/ae.mobile/delivery-schedule/reducers";
import returnSlotReducer from "../components/ae.mobile/return-repair-request-card/reducers";
import loanPreApprovalReducer from "../components/ae.mobile/loan-pre-approval/reducers";
import postBookingDeliveryReducer from "../components/ae.mobile/post-booking-delivered-card/reducers";
import AiRecommendedCarsReducer from "../components/ae.mobile/ai-recommended-cars/reducers";
import LoanEligibilityReducer from "../components/ae.mobile/loan-eligibility-modal/reducers";
// import carCareReducer from "../components/ae.mobile/car-care/reducers"; // shifted to dynamic injection
// import subscriptionVehicleDetails from "../components/ae.mobile/subscription-vehicle-details/reducers"; // shifted to dynamic injection

// Seller Reducers
import brandsReducer from "../components/ae.mobile/lead-brands/reducers";
import modelsReducer from "../components/ae.mobile/lead-models/reducers";
import yearsReducer from "../components/ae.mobile/lead-years/reducers";
import carEvaluationReducer from "../components/ae.mobile/car-evaluation-info/reducers";
import sellerRevaluationReducer from "../components/ae.mobile/seller-revaluation/reducers";
import carStoresReducer from "../components/ae.mobile/sell-car-appointment-store/reducers";
import testimonialsReducer from "../components/ae.mobile/sell-online-testimonials/reducers";
import postPriceAcceptanceReducer from "../components/ae.mobile/seller-car-handover/reducers";
import sellerLeadsReducer from "../components/ae.mobile/seller-leads-return-user-snackbar/reducers";
import sellerNpsReducer from "../components/ae.mobile/seller-nps-feedback/reducers";
import marketPlaceReducer from "../components/ae.mobile/c2c-market-place/reducers";
import sellerCenterReducer from "../components/ae.mobile/c2c-seller-center-info/reducers";

//Bank Valuation Certificate Reducers
import bvcCarEvaluationReducer from "../components/ae.mobile/bvc-vehicle-info/reducers";
import bvcLeadsReducer from "../components/ae.mobile/bvc-leads-return-user-snackbar/reducers";

//Desktop Modal view config reducers
import desktopModalViewConigReducer from "../components/ae.mobile/desktop-modal-view-config/reducers";

export default {
    [reducerConstants.USER]: resettableReducer(reducerConstants.USER)(userReducer),
    [reducerConstants.CITIES]: resettableReducer(reducerConstants.CITIES)(citiesReducer),
    [reducerConstants.CONFIG]: resettableReducer(reducerConstants.CONFIG)(configReducer),
    [reducerConstants.CAR_DETAILS]: resettableReducer(reducerConstants.CAR_DETAILS)(carDetailsReducer),
    [reducerConstants.CAR_LISTING]: resettableReducer(reducerConstants.CAR_LISTING)(carListReducer),
    [reducerConstants.FILTERS]: resettableReducer(reducerConstants.FILTERS)(filtersReducer),
    [reducerConstants.LOCATION_PICKER]: resettableReducer(reducerConstants.LOCATION_PICKER)(locationPickerReducer),
    [reducerConstants.CHECKOUT]: resettableReducer(reducerConstants.CHECKOUT)(checkoutReducer),
    [reducerConstants.EMI_DETAILS]: resettableReducer(reducerConstants.EMI_DETAILS)(emiDetailsReducer),
    [reducerConstants.EXPERIMENTS]: resettableReducer(reducerConstants.EXPERIMENTS)(experimentsReducer),
    [reducerConstants.MY_BOOKINGS]: resettableReducer(reducerConstants.MY_BOOKINGS)(myBookings),
    [reducerConstants.MY_WISHLIST]: resettableReducer(reducerConstants.MY_WISHLIST)(myWishlist),
    // [reducerConstants.TOF_SERVICE]: resettableReducer(reducerConstants.TOF_SERVICE)(tofService),
    // [reducerConstants.TOF_SERVICE_SCHEDULE]: resettableReducer(reducerConstants.TOF_SERVICE_SCHEDULE)(tofServiceSchedule),
    [reducerConstants.CAR_LOAN]: resettableReducer(reducerConstants.CAR_LOAN)(carLoan),
    [reducerConstants.SEO_CAR_DETAILS]: resettableReducer(reducerConstants.SEO_CAR_DETAILS)(seoCarDetails),
    [reducerConstants.TOAST]: resettableReducer(reducerConstants.TOAST)(toastMessageReducer),
    [reducerConstants.BACK_TO_TOP]: resettableReducer(reducerConstants.BACK_TO_TOP)(backToTopReducer),
    [reducerConstants.RECENTLY_VIEWED]: resettableReducer(reducerConstants.RECENTLY_VIEWED)(recentlyViewedCarsReducer),
    [reducerConstants.FAQ]: resettableReducer(reducerConstants.FAQ)(faqReducer),
    [reducerConstants.USER_CF_DETAILS]: resettableReducer(reducerConstants.USER_CF_DETAILS)(userCfDetailsReducer),
    [reducerConstants.CAR_CF_DETAILS]: resettableReducer(reducerConstants.CAR_CF_DETAILS)(carCfDetailsReducer),
    [reducerConstants.CHAT_ICON]: resettableReducer(reducerConstants.CHAT_ICON)(chatIconReducer),
    [reducerConstants.SALESFORCE]: resettableReducer(reducerConstants.SALESFORCE)(salesforceUploadsReducer),
    [reducerConstants.COMPARE_CARS]: resettableReducer(reducerConstants.COMPARE_CARS)(compareCars),
    [reducerConstants.PAYMENT_STATUS]: resettableReducer(reducerConstants.PAYMENT_STATUS)(PaymentReducer),
    // [reducerConstants.POST_BC_DETAILS]: resettableReducer(reducerConstants.POST_BC_DETAILS)(PostBcReducer),
    // [reducerConstants.TD_SCHEDULE]: resettableReducer(reducerConstants.TD_SCHEDULE)(tdScheduleReducer),
    // [reducerConstants.C2C_TD_SCHEDULE]: resettableReducer(reducerConstants.C2C_TD_SCHEDULE)(c2cTdScheduleReducer),
    // [reducerConstants.DELIVERY_SCHEDULE]: resettableReducer(reducerConstants.DELIVERY_SCHEDULE)(deliveryScheduleReducer),
    [reducerConstants.FEEDBACK]: resettableReducer(reducerConstants.FEEDBACK)(feedbackReducer),
    [reducerConstants.RETURN_AVAILABLE_SLOT]: resettableReducer(reducerConstants.USER)(returnSlotReducer),
    // [reducerConstants.POST_BC_VAS]: resettableReducer(reducerConstants.POST_BC_VAS)(PostBcVasReducer),
    [reducerConstants.LOAN_PRE_APPROVAL]: resettableReducer(reducerConstants.USER)(loanPreApprovalReducer),
    [reducerConstants.POST_BOOKING_DELIVERY]: resettableReducer(reducerConstants.POST_BOOKING_DELIVERY)(postBookingDeliveryReducer),
    [reducerConstants.AI_RECOMMENDED_CARS]: resettableReducer(reducerConstants.AI_RECOMMENDED_CARS)(AiRecommendedCarsReducer),
    // [reducerConstants.CAR_CARE]: resettableReducer(reducerConstants.CAR_CARE)(carCareReducer),
    // [reducerConstants.SUBSCRIPTION_VEHICLE_DETAILS]: resettableReducer(reducerConstants.SUBSCRIPTION_VEHICLE_DETAILS)(subscriptionVehicleDetails),
    // Seller
    [reducerConstants.CAR_EVALUATION]: resettableReducer(reducerConstants.CAR_EVALUATION)(carEvaluationReducer),
    [reducerConstants.BRANDS]: resettableReducer(reducerConstants.BRANDS)(brandsReducer),
    [reducerConstants.MODELS]: resettableReducer(reducerConstants.MODELS)(modelsReducer),
    [reducerConstants.YEARS]: resettableReducer(reducerConstants.YEARS)(yearsReducer),
    [reducerConstants.SELLER_REVALUATION]: resettableReducer(reducerConstants.SELLER_REVALUATION)(sellerRevaluationReducer),
    [reducerConstants.CAR_STORES]: resettableReducer(reducerConstants.CAR_STORES)(carStoresReducer),
    [reducerConstants.TESTIMONIALS]: resettableReducer(reducerConstants.TESTIMONIALS)(testimonialsReducer),
    [reducerConstants.POST_PADETAILS]: resettableReducer(reducerConstants.POST_PADETAILS)(postPriceAcceptanceReducer),
    [reducerConstants.SELLER_LEADS]: resettableReducer(reducerConstants.SELLER_LEADS)(sellerLeadsReducer),
    [reducerConstants.SELLER_NPS]: resettableReducer(reducerConstants.SELLER_NPS)(sellerNpsReducer),
    [reducerConstants.LOAN_ELIGIBILITY]: resettableReducer(reducerConstants.LOAN_ELIGIBILITY)(LoanEligibilityReducer),
    [reducerConstants.MARKET_PLACE]: resettableReducer(reducerConstants.MARKET_PLACE)(marketPlaceReducer),
    [reducerConstants.SELLER_CENTER]: resettableReducer(reducerConstants.SELLER_CENTER)(sellerCenterReducer),
     // Bank Valuation Certificate
    [reducerConstants.BVC_CAR_EVALUATION]: resettableReducer(reducerConstants.BVC_CAR_EVALUATION)(bvcCarEvaluationReducer),
    [reducerConstants.BVC_LEADS]: resettableReducer(reducerConstants.BVC_LEADS)(bvcLeadsReducer),
    //Desktop Modal view config reducers
    [reducerConstants.DESKTOP_MODAL_VIEW_CONFIG]: resettableReducer(reducerConstants.DESKTOP_MODAL_VIEW_CONFIG)(desktopModalViewConigReducer)

};
