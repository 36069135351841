import React from "react";

// Styles
import styles from "./styles.css";

// Images
import reloadIcon from "../../shared/images/reload-icon.svg";

// Components
import Button from "../../shared/button";

// Hooks
import { useIsOnline } from "../../../hooks/use-is-online";
import { NoInternetIcon } from "./images/internet-connection-icon";

const NoInternet = () => {
    const isOnline = useIsOnline();

    const reloadPage = () => {
        if (window !== undefined) {
            const location = window.location;
            location.reload(false);
        }
    };

    return (
        !isOnline ? <div styleName={`styles.wrapper ${isOnline ? "" : "styles.noInternet"}`}>
            <span styleName={"styles.mainImg"} dangerouslySetInnerHTML={{__html: NoInternetIcon}} />
            <h3 styleName={"styles.titleText"}>We Lost You...</h3>
            <p styleName={"styles.contentText"}>Slow or no internet connection<br/> Please check your internet settings</p>
            <Button leftImage={reloadIcon} text="TAP TO REFRESH" onClick={reloadPage} />
        </div> : null
    );
};

export default NoInternet;
