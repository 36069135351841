import { createReducer } from "../../../utils/redux/create-reducer";
import cleverTap from "../../../tracking/clevertap";
import { findByKey } from "../../../utils/helpers/find-by-key";
import Types from "./types";

export const INITIAL_STATE = {
    selectedCity: {}
};
export const updateCity = (state = INITIAL_STATE, { city, cityList }) => {
    const isCityName = isNaN(Number(city));
    const selectedCity = findByKey(cityList, isCityName ? "name" : "id", city) || INITIAL_STATE.selectedCity;
    if (selectedCity) cleverTap.updateEmirate(selectedCity?.name);
    return { ...state, selectedCity };
};

export const updateCityFromCookie = (state = INITIAL_STATE, {data}) => {
    return { ...state, selectedCity: { ...state.selectedCity, ...(data || {})} };
};

export const updateCityWithCode = (state = INITIAL_STATE, { cityCode, cityList, lat, lng, hasCitySkip = false, name }) => {
    const selectedCity = findByKey(cityList, "code", cityCode) || INITIAL_STATE.selectedCity;
    return { ...state, selectedCity: { ...selectedCity, lat, lng, hasCitySkip, name}};
};

export const updateHubLocations = (state = INITIAL_STATE, { locations }) => {
    return { ...state, locations: [...(locations || [])]};
};

export const updateHubSelection = (state = INITIAL_STATE, { selectedHub }) => {
    return { ...state, selectedHub: {...(selectedHub || {})}};
};

export const HANDLERS = {
    [Types.UPDATE_CITY]: updateCity,
    [Types.UPDATE_CITY_WITH_CODE]: updateCityWithCode,
    [Types.SET_LOCATIONS]: updateHubLocations,
    [Types.UPDATE_HUB_SELECTED]: updateHubSelection,
    [Types.UPDATE_CITY_FROM_COOKIE]: updateCityFromCookie

};

export default createReducer(INITIAL_STATE, HANDLERS);
