import { createReducer } from "../../../utils/redux/create-reducer";
import { NUMBER } from "../../../constants/app-constants";
import { ROUTE_NAME } from "../../../constants/routes-constants";
import Types from "./types";

export const INITIAL_STATE = {
    show: false,
    routes: [ROUTE_NAME.HOME, ROUTE_NAME.LISTING, ROUTE_NAME.DETAIL_PAGE, ROUTE_NAME.SELL_ONLINE],
    top: NUMBER.EIGHTY
};

export const setBackToTop = (state = INITIAL_STATE, { payload }) => {
    const { show = false, top = NUMBER.EIGHTY } = payload;
    return { ...state, show, top };
};

export const HANDLERS = {
    [Types.SET_BACK_TO_TOP]: setBackToTop
};

export default createReducer(INITIAL_STATE, HANDLERS);
