import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";

export const INITIAL_STATE = {
    details: {
        userDetail: {
            employmentType: null,
            monthlyIncome: "",
            isLoanOrEmiPresent: null,
            totalLoanOrEmiAmount: "",
            isCreditCardPresent: null,
            totalCreditCardLimit: "",
            isPrequalified: null,
            phoneNumber: ""
        },
        quotesList: [],
        selectedQuote: {},
        status: null,
        errorResponse: null,
        appointmentId: null,
        orderId: null
    },
    backURL: "/",
    isLoading: false,
    isCreating: false,
    isUpdating: false,

    isLoadingError: false,
    isCreateError: false,
    isUpdateError: false,
    error: null
};

export const getCarCfDetailsRequest = (state = INITIAL_STATE) => {
    return {
        ...state,
        isLoading: true,
        isLoadingError: false,
        error: null
    };
};

export const getCarCfDetailsSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        details: { ...state.details, ...data },
        isLoading: false,
        isLoadingError: false,
        error: null
    };
};

export const getCarCfDetailsFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        isLoading: false,
        isLoadingError: true,
        error
    };
};

export const createCarCfDetailsRequest = (state = INITIAL_STATE) => {
    return {
        ...state,
        isCreating: true,
        isCreatingError: false,
        error: null
    };
};

export const createCarCfDetailsSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        details: { ...state.details, ...data },
        isCreating: false,
        isCreatingError: false,
        error: null
    };
};

export const createCarCfDetailsFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        isCreating: false,
        isCreatingError: true,
        error
    };
};

export const updateCarCfDetailsRequest = (state = INITIAL_STATE) => {
    return {
        ...state,
        isUpdating: true,
        isUpdateError: false,
        error: null
    };
};

export const updateCarCfDetailsSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        details: { ...state.details, ...data },
        isUpdating: false,
        isUpdatingError: false,
        error: null
    };
};

export const updateCarCfDetailsFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        isUpdating: false,
        isUpdatingError: true,
        error
    };
};

export const setCarCfBackURL = (state = INITIAL_STATE, { backURL }) => {
    return {
        ...state,
        backURL
    };
};

export const HANDLERS = {
    [Types.GET_CAR_CF_DETAILS_REQUEST]: getCarCfDetailsRequest,
    [Types.GET_CAR_CF_DETAILS_SUCCESS]: getCarCfDetailsSuccess,
    [Types.GET_CAR_CF_DETAILS_FAILURE]: getCarCfDetailsFailure,

    [Types.CREATE_CAR_CF_DETAILS_REQUEST]: createCarCfDetailsRequest,
    [Types.CREATE_CAR_CF_DETAILS_SUCCESS]: createCarCfDetailsSuccess,
    [Types.CREATE_CAR_CF_DETAILS_FAILURE]: createCarCfDetailsFailure,

    [Types.UPDATE_CAR_CF_DETAILS_REQUEST]: updateCarCfDetailsRequest,
    [Types.UPDATE_CAR_CF_DETAILS_SUCCESS]: updateCarCfDetailsSuccess,
    [Types.UPDATE_CAR_CF_DETAILS_FAILURE]: updateCarCfDetailsFailure,

    [Types.SET_CAR_CF_BACK_URL]: setCarCfBackURL
};

export default createReducer(INITIAL_STATE, HANDLERS);
