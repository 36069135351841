import React from "react";
import withAELayout from "../../../components/ae.mobile/layout";
import ReturnRepairChatInfo from "../../../components/ae.mobile/return-repair-chat-info";

const ReturnRepairChat = (props) => {
  return (
    <React.Fragment>
        <ReturnRepairChatInfo {...props} />
    </React.Fragment>
  );
};

export default withAELayout(ReturnRepairChat, false, false);
