export const superAppDefaultConfig = {
    isDefaultConfig: true,
    topBanner: {
        title: {
            text: ["<greetings>"],
            color: "#001647f",
            "variable": [
                {
                    "key": "greetings",
                    "value": "Welcome to CARS24 👋",
                    "style": "BOLD"
                }
            ]
        },
        subTitle: {
            text: ["<greetings> booking for pre owned cars?"],
            color: "#001647",
            "variable": [
                {
                    "key": "greetings",
                    "value": "Hey,",
                    "style": "BOLD"
                }
            ]
        },
        location: {
            textColor: "#001647",
            locationIcon: "https://media-ae.cars24.com/ae/icon/icon_location_orange.png",
            caretDownIcon: "https://media-ae.cars24.com/ae/icon/icon_location_down_arrow.png"
        },
        wishlist: {
            wishlistIcon: "https://media-ae.cars24.com/ae/icon/icon_wishlist_blue.png",
            wishlistCountTextBackgroundColor: "#FF870F",
            wishlistCountTextColor: "#ffffff"
        },
        appDownload: {
            textColor: "#0032A6",
            backgroundColor: "#ffffff",
            downloadArrowIcon: "https://media-ae.cars24.com/ae/icon/icon_download_blue.png"
        },
        ctaInfo: {
            title: "View All",
            backgroundColor: "#ED6F00",
            textColor: "#ffffff",
            redirectPath: "/buy-used-cars-dubai/"
        },
        additionalData: {
            backgroundGradients: "180deg, #B0D7F8 11.9%, #DBEDFC 100%",
            backgroundBannerImage: "https://media-ae.cars24.com/ae/banner/Banner_Top_Default_1.png"
        },
        showTopBanner: true
    },
    superAppServices: [
        {
            title: "Buy car",
            subTitle: "Best in class cars",
            badge: "SALE",
            badgeBackgroundColor: "#03A65A",
            badgeTextColor: "#FFFFFF",
            backgroundColor: "#E8F6FF",
            icon: "https://media-ae.cars24.com/ae/icon/icon_buy_car_service.png",
            path: "/buy-used-cars-dubai/",
            primary: true,
            gaEventName: "superAppHpBuyCarSelect"
        },
        {
            title: "Sell car",
            subTitle: "Sell at best price",
            badge: "BEST PRICE",
            badgeBackgroundColor: "#52007E",
            badgeTextColor: "#FFFFFF",
            backgroundColor: "#E8F6FF",
            icon: "https://media-ae.cars24.com/ae/icon/icon_sell_car.png",
            path: "/sell-used-car/",
            primary: true,
            gaEventName: "superAppHpSellCarSelect"
        },
        {
            title: "Car\nloan",
            backgroundColor: "#E8F6FF",
            badgeColor: "",
            icon: "https://media-ae.cars24.com/ae/icon/icon_car_loan.png",
            path: "/car-loan/",
            gaEventName: "superAppHpCarLoanSelect"
        },
        {
            title: "Car\nservicing",
            backgroundColor: "#E8F6FF",
            badge: "",
            badgeColor: "",
            icon: "https://media-ae.cars24.com/ae/icon/icon_periodic_service.png",
            path: "/car-servicing/",
            gaEventName: "superAppHpCarServicingSelect"
        },
        {
            title: "Valuation\ncertificate",
            backgroundColor: "#E8F6FF",
            badge: "New",
            badgeColor: "blue",
            icon: "https://media-ae.cars24.com/ae/icon/icon_bank_certificate.png",
            path: "/bank-valuation-certificate/",
            gaEventName: "superAppHpBvcSelect"
        }
    ],
    exclusiveOffers: [
        {
            title: {
                text: "Exchange\nyour car"
            },
            subTitle: {
                text: "Get up to AED 2000 off"
            },
            bgImage: "https://media-ae.cars24.com/ae/banner/Exchange_Car_banner.png",
            backgroundColor: "#FFFFFF",
            badge: {
                text: "EXCHANGE CAR",
                color: "#FF870F",
                gradient: ["#FFFFFFFF", "#FFFFFF00"]
            },
            cta: {
                title: "View Offers",
                path: "/buy-used-cars-uae"
            }
        },
        {
            title: {
                text: "Make your\ncar dreams real"
            },
            subTitle: {
                text: "with a personal loan"
            },
            bgImage: "https://media-ae.cars24.com/ae/banner/Loan_banner.png",
            backgroundColor: "#FFFFFF",
            badge: {
                text: "LOANS",
                color: "#4170D9",
                gradient: ["#FFFFFFFF", "#FFFFFF00"]
            },
            cta: {
                title: "Check now",
                path: "/car-loan"
            }
        }
    ]
};

export const bottomNavDefaultConfig = {
    isDefaultConfig: true,
    "name": "Bottom Navigation (Msite)",
    "slug": "bottom-navigation-config",
    "seo": "",
    "breadcrumb": [],
    "schema": "",
    "pageSections": [
        {
            "displayOrder": 1,
            "pageSection": {
                "id": 3450,
                "name": "ae.home",
                "title": "Home Page Bottom Navigation",
                "sectionType": "Grid",
                "sectionData": [
                    {
                        "image": {
                            "id": null,
                            "url": null,
                            "caption": null,
                            "alternativeText": null
                        },
                        "title": "ae.home",
                        "description": "",
                        "primaryCtaUrl": "/",
                        "primaryCtaText": "Home",
                        "primaryCtaType": "NAVIGATE"
                    },
                    {
                        "image": {
                            "id": null,
                            "url": null,
                            "caption": null,
                            "alternativeText": null
                        },
                        "title": "ae.listing",
                        "primaryCtaUrl": "/buy-used-cars-dubai/",
                        "primaryCtaText": "Buy car",
                        "primaryCtaType": "NAVIGATE"
                    },
                    {
                        "image": {
                            "id": null,
                            "url": null,
                            "caption": null,
                            "alternativeText": null
                        },
                        "title": "ae.sell-online",
                        "primaryCtaUrl": "/sell-used-car/",
                        "primaryCtaText": "Sell car",
                        "primaryCtaType": "NAVIGATE"
                    },
                    {
                        "image": {
                            "id": null,
                            "url": null,
                            "caption": null,
                            "alternativeText": null
                        },
                        "title": "ae.car-loan",
                        "primaryCtaUrl": "/car-loan/",
                        "primaryCtaText": "Loan",
                        "primaryCtaType": "NAVIGATE"
                    },
                    {
                        "image": {
                            "id": null,
                            "url": null,
                            "caption": null,
                            "alternativeText": null
                        },
                        "title": "ae.menu",
                        "primaryCtaText": "Menu",
                        "primaryCtaType": "POPUP"
                    }
                ],
                "subsectionOrder": null,
                "buNames": [],
                "statsig": false
            }
        },
        {
            "displayOrder": 2,
            "pageSection": {
                "id": 3452,
                "name": "ae.listing",
                "title": "Buyer Listing Page Bottom Navigation",
                "sectionType": "Grid",
                "sectionData": [
                    {
                        "image": {
                            "id": null,
                            "url": null,
                            "caption": null,
                            "alternativeText": null
                        },
                        "title": "back",
                        "primaryCtaUrl": "/",
                        "primaryCtaText": "Home",
                        "primaryCtaType": "NAVIGATE"
                    },
                    {
                        "image": {
                            "id": null,
                            "url": null,
                            "caption": null,
                            "alternativeText": null
                        },
                        "title": "ae.listing",
                        "primaryCtaUrl": "/buy-used-cars-dubai/",
                        "primaryCtaText": "Buy car",
                        "primaryCtaType": "NAVIGATE"
                    },
                    {
                        "image": {
                            "id": null,
                            "url": null,
                            "caption": null,
                            "alternativeText": null
                        },
                        "title": "ae.sell-online",
                        "primaryCtaUrl": "/sell-used-car/",
                        "primaryCtaText": "Sell car",
                        "primaryCtaType": "NAVIGATE"
                    },
                    {
                        "image": {
                            "id": null,
                            "url": null,
                            "caption": null,
                            "alternativeText": null
                        },
                        "title": "ae.car-loan",
                        "primaryCtaUrl": "/car-loan/",
                        "primaryCtaText": "Loan",
                        "primaryCtaType": "NAVIGATE"
                    },
                    {
                        "image": {
                            "id": null,
                            "url": null,
                            "caption": null,
                            "alternativeText": null
                        },
                        "title": "ae.menu",
                        "primaryCtaText": "Menu",
                        "primaryCtaType": "POPUP"
                    }
                ],
                "subsectionOrder": null,
                "buNames": [],
                "statsig": false
            }
        },
        {
            "displayOrder": 3,
            "pageSection": {
                "id": 3455,
                "name": "ae.sell-online",
                "title": "Sell Car Page Bottom Navigation",
                "sectionType": "Grid",
                "sectionData": [
                    {
                        "image": {
                            "id": null,
                            "url": null,
                            "caption": null,
                            "alternativeText": null
                        },
                        "title": "back",
                        "primaryCtaUrl": "/",
                        "primaryCtaText": "Home",
                        "primaryCtaType": "NAVIGATE"
                    },
                    {
                        "image": {
                            "id": null,
                            "url": null,
                            "caption": null,
                            "alternativeText": null
                        },
                        "title": "ae.listing",
                        "primaryCtaUrl": "/buy-used-cars-dubai/",
                        "primaryCtaText": "Buy car",
                        "primaryCtaType": "NAVIGATE"
                    },
                    {
                        "image": {
                            "id": null,
                            "url": null,
                            "caption": null,
                            "alternativeText": null
                        },
                        "title": "ae.sell-online",
                        "primaryCtaUrl": "/sell-used-car/",
                        "primaryCtaText": "Sell car",
                        "primaryCtaType": "NAVIGATE"
                    },
                    {
                        "image": {
                            "id": null,
                            "url": null,
                            "caption": null,
                            "alternativeText": null
                        },
                        "title": "ae.car-loan",
                        "primaryCtaUrl": "/car-loan/",
                        "primaryCtaText": "Loan",
                        "primaryCtaType": "NAVIGATE"
                    },
                    {
                        "image": {
                            "id": null,
                            "url": null,
                            "caption": null,
                            "alternativeText": null
                        },
                        "title": "ae.menu",
                        "primaryCtaText": "Menu",
                        "primaryCtaType": "POPUP"
                    }
                ],
                "subsectionOrder": null,
                "buNames": [],
                "statsig": false
            }
        },
        {
            "displayOrder": 4,
            "pageSection": {
                "id": 3458,
                "name": "ae.car-loan",
                "title": "Loan Page Bottom Navigation",
                "sectionType": "Grid",
                "sectionData": [
                    {
                        "image": {
                            "id": null,
                            "url": null,
                            "caption": null,
                            "alternativeText": null
                        },
                        "title": "back",
                        "primaryCtaUrl": "/",
                        "primaryCtaText": "Home",
                        "primaryCtaType": "NAVIGATE"
                    },
                    {
                        "image": {
                            "id": null,
                            "url": null,
                            "caption": null,
                            "alternativeText": null
                        },
                        "title": "ae.car-loan",
                        "primaryCtaUrl": "/car-loan/",
                        "primaryCtaText": "Loan",
                        "primaryCtaType": "NAVIGATE"
                    },
                    {
                        "image": {
                            "id": null,
                            "url": null,
                            "caption": null,
                            "alternativeText": null
                        },
                        "title": "ae.bank-valuation-report",
                        "primaryCtaUrl": "/bank-valuation-certificate/",
                        "primaryCtaText": "Valuation",
                        "primaryCtaType": "NAVIGATE"
                    },
                    {
                        "image": {
                            "id": null,
                            "url": null,
                            "caption": null,
                            "alternativeText": null
                        },
                        "title": "ae.listing",
                        "primaryCtaUrl": "/buy-used-cars-dubai",
                        "primaryCtaText": "Buy car",
                        "primaryCtaType": "NAVIGATE"
                    },
                    {
                        "image": {
                            "id": null,
                            "url": null,
                            "caption": null,
                            "alternativeText": null
                        },
                        "title": "ae.menu",
                        "primaryCtaText": "Menu",
                        "primaryCtaType": "POPUP"
                    }
                ],
                "subsectionOrder": null,
                "buNames": [],
                "statsig": false
            }
        },
        {
            "displayOrder": 5,
            "pageSection": {
                "id": 3469,
                "name": "ae.car-care",
                "title": " Car Care Bottom Navigation",
                "sectionType": "Grid",
                "sectionData": [
                    {
                        "image": {
                            "id": null,
                            "url": null,
                            "caption": null,
                            "alternativeText": null
                        },
                        "title": "back",
                        "primaryCtaUrl": "/",
                        "primaryCtaText": "Home",
                        "primaryCtaType": "NAVIGATE"
                    },
                    {
                        "image": {
                            "id": null,
                            "url": null,
                            "caption": null,
                            "alternativeText": null
                        },
                        "title": "ae.car-servicing",
                        "primaryCtaUrl": "/car-servicing/",
                        "primaryCtaText": "Service",
                        "primaryCtaType": "NAVIGATE"
                    },
                    {
                        "image": {
                            "id": null,
                            "url": null,
                            "caption": null,
                            "alternativeText": null
                        },
                        "title": "ae.car-care",
                        "primaryCtaUrl": "/car-care/",
                        "primaryCtaText": "Car care",
                        "primaryCtaType": "NAVIGATE"
                    },
                    {
                        "image": {
                            "id": null,
                            "url": null,
                            "caption": null,
                            "alternativeText": null
                        },
                        "title": "ae.menu",
                        "primaryCtaText": "Menu",
                        "primaryCtaType": "POPUP"
                    }
                ],
                "subsectionOrder": null,
                "buNames": [],
                "statsig": false
            }
        },
        {
            "displayOrder": 6,
            "pageSection": {
                "id": 3530,
                "name": "ae.car-servicing",
                "title": "Servicing Page Bottom Navigation",
                "sectionType": "Grid",
                "sectionData": [
                    {
                        "image": {
                            "id": null,
                            "url": null,
                            "caption": null,
                            "alternativeText": null
                        },
                        "title": "back",
                        "primaryCtaUrl": "/",
                        "primaryCtaText": "Home",
                        "primaryCtaType": "NAVIGATE"
                    },
                    {
                        "image": {
                            "id": null,
                            "url": null,
                            "caption": null,
                            "alternativeText": null
                        },
                        "title": "ae.car-servicing",
                        "primaryCtaUrl": "/car-servicing/",
                        "primaryCtaText": "Service",
                        "primaryCtaType": "NAVIGATE"
                    },
                    {
                        "image": {
                            "id": null,
                            "url": null,
                            "caption": null,
                            "alternativeText": null
                        },
                        "title": "ae.car-care",
                        "primaryCtaUrl": "/car-care/",
                        "primaryCtaText": "Car care",
                        "primaryCtaType": "NAVIGATE"
                    },
                    {
                        "image": {
                            "id": null,
                            "url": null,
                            "caption": null,
                            "alternativeText": null
                        },
                        "title": "ae.menu",
                        "primaryCtaText": "Menu",
                        "primaryCtaType": "POPUP"
                    }
                ],
                "subsectionOrder": null,
                "buNames": [],
                "statsig": false
            }
        },
        {
            "displayOrder": 7,
            "pageSection": {
                "id": 3551,
                "name": "ae.bank-valuation-report",
                "title": "Valuation Certificate Bottom Navigation",
                "sectionType": "Grid",
                "sectionData": [
                    {
                        "title": "back",
                        "primaryCtaUrl": "/",
                        "primaryCtaText": "Home"
                    },
                    {
                        "image": {
                            "id": null,
                            "url": null,
                            "caption": null,
                            "alternativeText": null
                        },
                        "title": "ae.car-loan",
                        "primaryCtaUrl": "/car-loan",
                        "primaryCtaText": "Loan",
                        "primaryCtaType": "NAVIGATE"
                    },
                    {
                        "image": {
                            "id": null,
                            "url": null,
                            "caption": null,
                            "alternativeText": null
                        },
                        "title": "ae.bank-valuation-report",
                        "primaryCtaUrl": "/bank-valuation-certificate/",
                        "primaryCtaText": "Valuation",
                        "primaryCtaType": "NAVIGATE"
                    },
                    {
                        "image": {
                            "id": null,
                            "url": null,
                            "caption": null,
                            "alternativeText": null
                        },
                        "title": "ae.listing",
                        "primaryCtaUrl": "/buy-used-cars-dubai/",
                        "primaryCtaText": "Buy car",
                        "primaryCtaType": "NAVIGATE"
                    },
                    {
                        "image": {
                            "id": null,
                            "url": null,
                            "caption": null,
                            "alternativeText": null
                        },
                        "title": "ae.menu",
                        "primaryCtaText": "Menu",
                        "primaryCtaType": "POPUP"
                    }
                ],
                "subsectionOrder": null,
                "buNames": [],
                "statsig": false
            }
        }
    ],
    "globalCities": null
};

