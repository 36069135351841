import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
`
    AI_RECOMMENDED_CARS
    AI_RECOMMENDED_API_ERROR
    INIT
`,
{
    prefix: "airecommendedcars/"
}
);
