import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import FooterV2 from "./component";
import { updateCity } from "../location-picker/actions";
import { clearAllFilters } from "../filters/actions";

const mapStateToProps = ({
    config: {
        cityList,
        footerSection
    }
}) => ({
    cityList,
    footerSection
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateCityConnect: updateCity,
    clearAllFiltersConnect: clearAllFilters
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FooterV2);
