import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";

export const INITIAL_STATE = {};

const apiRequest = (state = INITIAL_STATE, { isSubmit }) => {
    return { ...state, isLoading: isSubmit ? false : true, isUpdating: isSubmit };
};
const apiResponse = (state = INITIAL_STATE) => {
    return { ...state, isLoading: false, isUpdating: false };
};
const updateGsOffer = (state = INITIAL_STATE, {data}) => {
    return { ...state, gsOffer: {...data} };
};
const setInventoryData = (state = INITIAL_STATE, {data}) => {
    return { ...state, ...data };
};
const setPriceDetails = (state = INITIAL_STATE, {data}) => {
    return { ...state, inventory: {...(state?.inventory || {}), ...data} };
};
const setSuccessFee = (state = INITIAL_STATE, {data}) => {
    return { ...state, ...data};
};
const setSelectedCardDetails = (state = INITIAL_STATE, {data}) => {
    return { ...state, selectedCard: {...data}};
};
const setBIData = (state = INITIAL_STATE, {data}) => {
    return { ...state, biData: {...data}};
};

export const HANDLERS = {
    [Types.API_REQUEST]: apiRequest,
    [Types.API_RESPONSE]: apiResponse,
    [Types.UPDATE_GS_OFFER]: updateGsOffer,
    [Types.SET_INVENTORY_DATA]: setInventoryData,
    [Types.SET_PRICE_DETAILS]: setPriceDetails,
    [Types.SET_SUCCESS_FEE]: setSuccessFee,
    [Types.SET_SELECTED_CARD]: setSelectedCardDetails,
    [Types.SET_BI_DATA]: setBIData
};

export default createReducer(INITIAL_STATE, HANDLERS);
