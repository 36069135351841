import { createReducer } from "../../../utils/redux/create-reducer";
import sortByKey from "../../../utils/helpers/sort-by-key";
import Types from "./types";

const getHomepageFaqs = (fetchedFaqs) => {
    let destructedListFaqs = [];
    fetchedFaqs.forEach((item) => {
        destructedListFaqs = [...destructedListFaqs, ...item.faqList];
    });
    return destructedListFaqs;
};

export const INITIAL_STATE = {
    content: null,
    homePageFaq: null,
    sellerFaq: null,
    bvcPageFaq: null,
    isFetching: false
};

export const fetchFaqInit = (state = INITIAL_STATE) => {
    return { ...state, isFetching: true };
};

export const fetchFaqSuccess = (state = INITIAL_STATE, { content, isHomepage, isSeller, isBvc = false }) => {
    if (isBvc) {
        content = content.find(data => data?.categoryTitle === "Bank Valuation Certificate") || {};
    }
    return {
        ...state,
        ...((!isHomepage && !isSeller && !isBvc) && {
            content: content.map(item => ({ ...item, faqList: sortByKey(item.faqList, "faqSequence", "asc") }))
        }),
        ...(isHomepage && {
            homePageFaq: getHomepageFaqs(content)
        }),
        ...(isSeller && {
            sellerFaq: content.map(item => ({ ...item, faqList: sortByKey(item.faqList, "faqSequence", "asc") }))
        }),
        ...(isBvc && {
            bvcPageFaq: content?.faqList
        }),

        isFetching: false,
        error: null
    };
};

export const fetchFaqFailure = (state = INITIAL_STATE, { error }) => {
    return { ...state, error, isFetching: false };
};

export const HANDLERS = {
    [Types.FETCH_FAQ]: fetchFaqInit,
    [Types.FETCH_FAQ_SUCCESS]: fetchFaqSuccess,
    [Types.FETCH_FAQ_FAILURE]: fetchFaqFailure
};

export default createReducer(INITIAL_STATE, HANDLERS);
