import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from "react-helmet-async";

// Styles
import styles from "./styles.css";

// Images
import CarLogo from "./images/logo.svg";
import Call from "./images/call.svg";

// Tracking
import { trackWebViewEvents } from "../../../utils/webview-tracking";

// Constants
import { APP_NAVIGATIONS, WEBVIEW_EVENTS } from "../../../constants";
import { CALL_NUMBER } from "../../../constants/app-constants";

// Component
import Button from "../../shared/button";

const PageLoadError = ({
    history,
    onClose,
    isWebview,
    title = "Oops",
    header = "Something went wrong.",
    titleRequired = true,
    subTitleRequired = true
}) => {
    const handleLogoClick = () => {
        if (isWebview) {
            trackWebViewEvents(WEBVIEW_EVENTS.REDIRECT, { type: APP_NAVIGATIONS.home });
        } else {
            history.replace("/");
        }
    };

    const onCall = () => {
        if (isWebview) {
            trackWebViewEvents(WEBVIEW_EVENTS.HEADER_CALL);
        } else if (window) {
            window.open(`tel:${CALL_NUMBER}`);
        }
    };

    const handleError = () => {
        if (onClose) {
            onClose();
        } else {
            handleLogoClick();
        }
    };

    return (
        <React.Fragment>
            <Helmet>
                <meta name="ROBOTS" content="NOINDEX,FOLLOW" />
            </Helmet>
            <div styleName={"styles.header"}>
                <img src={CarLogo} alt="Cars24 Logo" onClick={handleLogoClick} />
                <img src={Call} alt="call icon" onClick={onCall} />
            </div>
            <div styleName={"styles.notFound"}>
                {titleRequired && <h4 styleName={"styles.oops"}>{title}</h4>}
                <p>{header}</p>
                {subTitleRequired && <p>Please refresh the page or click <br /> here to go back.</p>}
                <Button
                    text={"Go back"}
                    onClick={handleError}
                />
            </div>
        </React.Fragment>
    );
};

PageLoadError.propTypes = {
    title: PropTypes.string,
    history: PropTypes.object.isRequired,
    onClose: PropTypes.func,
    isWebview: PropTypes.bool,
    header: PropTypes.string,
    titleRequired: PropTypes.bool,
    subTitleRequired: PropTypes.bool
};

const mapStateToProps = ({
    user: {
        isWebview
    }
}) => ({
    isWebview
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PageLoadError));
