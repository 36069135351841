import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    SET_BACK_TO_TOP
`,
    {
        prefix: "backToTop/"
    }
);
