import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";

// Constants
import { LOCALSTORAGE_CONSTANTS, NUMBER } from "../../../constants/app-constants";

// Utilities
import getFiltersData from "../../../utils/filters-v2/get-filters-data";
import getDefaultFilterSuggestionsData from "../../../utils/filters-v2/get-default-filter-suggestions";
import { ALL_FILTERS_MOBILE, FILTER_TAB_TYPES } from "../../../utils/filters-v2/constants";
import { getSortData } from "../../../utils/filters-v2/get-sort-data";
import getUpdatedAppliedFilters, { getUpdatedSubFilterOptions } from "../../../utils/filters-v2/get-updated-applied-filters";
import { clearItem } from "../../../utils/helpers/storage-crud";

const SEARCH_TRACKING_RESULT_INITIAL_STATE = {
    typedQuery: "",
    searchQuery: "",
    filterType: ""
};

export const INITIAL_STATE = {
    isSSR: false,
    isLoading: false,
    isFetched: false,
    data: null,
    sorts: [],
    allFilters: {},
    onScreenFilters: [],
    offScreenFilter: [],
    selectedOnScreenFilter: "",
    showAllFilters: false,
    isListingCountFetched: false,
    isListingCountLoading: false,
    listingCount: null,
    trendingSearch: [],
    isTrendingSearchLoading: false,
    activeFilterTab: FILTER_TAB_TYPES.FILTER,
    selectedSort: {
        key: "releavance",
        displayText: "Best Match"
    },
    appliedFilters: [],           // Filters which are applied in the listing.
    selectedFilters: [],         // Filters which are selected but not yet applied in the listing
    selectedSuggestions: [],
    appliedSuggestions: [],
    urlOptions: {},
    customFilterData: {
        financePriceLimit: null
    },
    isSubFacetFullySelected: [],
    defaultFilterSuggestion: [],
    selectedFilterCount: null,
    onScreenFiltersList: ALL_FILTERS_MOBILE,
    isReturningUser: false,
    isShowFilterResumeSession: false,
    searchResultTrackingData: SEARCH_TRACKING_RESULT_INITIAL_STATE,
    recommendedCars: [],
    isRecommendedCarsLoading: true,
    customRecommendedCars: [],
    isCustomRecommendedCarsLoading: true,
    recentlyViewedCars: [],
    isRecentlyViewedCarsLoading: true,
    isRecentlyViewedSSR: false,
    recentlyAddedCars: [],
    isRecentlyAddedCarsLoading: true,
    showNudgeGlow: false,
    totalCars: NUMBER.ZERO
};

export const fetchFiltersInit = (state = INITIAL_STATE) => {
    return { ...state, isLoading: true };
};

export const fetchFiltersSuccess = (state = INITIAL_STATE, { data }) => {
    const filtersData = getFiltersData(data.filters);
    const sortsData = getSortData(data.sorts);
    const defaultFilterSuggestion = getDefaultFilterSuggestionsData(data.defaultFilterSuggestion);
    return {
        ...state,
        isFetched: true,
        isLoading: false,
        sorts: { ...sortsData },
        ...filtersData,
        defaultFilterSuggestion,
        totalCars: data.total
    };
};

export const fetchFiltersFailure = () => {
    return {
        ...INITIAL_STATE,
        isFetched: true,
        data: null,
        isLoading: false,
        lastUpdated: (new Date()).getTime()
    };
};

// export const fetchSSRFiltersSuccess = (state = INITIAL_STATE, {data}) => {
//     const filtersData = getFiltersData(filterMock.filters);
//     return {
//         ...state,
//         isFetched: true,
//         isLoading: false,
//         ...filtersData
//     };
// };

export const updateSSRStatus = (state = INITIAL_STATE, { isSSR }) => {
    return { ...state, isSSR };
};

export const updateSSRFiltersList = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        ...data
    };
};

export const updateFilterStamp = (state = INITIAL_STATE, { lastUpdated }) => {
    return { ...state, lastUpdated };
};

export const updateSelectedOnScreenFilter = (state = INITIAL_STATE, { selectedOnScreenFilter }) => {
    return {
        ...state,
        selectedOnScreenFilter
    };
};

// export const updateFilterOptionData = (state = INITIAL_STATE, {filterKey, filterOptionIndex, subFilterOptionIndex, filterData = {}, filterOptionData = {}, subFilterOptionData = {}}) => {
//     return {
//         ...state,
//         allFilters: getUpdatedFilters(state.allFilters, {filterKey, filterOptionIndex, subFilterOptionIndex, filterData, filterOptionData, subFilterOptionData})
//     };
// };

export const updateSelectedFiltersList = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        selectedFilters: getUpdatedAppliedFilters(data, state.selectedFilters)
    };
};

export const updateSelectedSuggestions = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        selectedSuggestions: getUpdatedAppliedFilters(data, state.selectedSuggestions)
    };
};

export const updateAllFiltersVisibility = (state = INITIAL_STATE, { showAllFilters }) => {
    return {
        ...state,
        showAllFilters
    };
};

export const fetchListingCountInit = (state = INITIAL_STATE) => {
    return { ...state, isListingCountLoading: true };
};

export const fetchListingCountSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        isListingCountFetched: true,
        isListingCountLoading: false,
        listingCount: data.total
    };
};

export const fetchListingCountFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        isListingCountFetched: true,
        isListingCountLoading: false
    };
};

export const fetchTrendingSearchInit = (state = INITIAL_STATE) => {
    return {
        ...state,
        isTrendingSearchLoading: true
    };
};

export const fetchTrendingSearchSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        isTrendingSearchLoading: false,
        trendingSearch: data
    };
};

export const fetchTrendingSearchFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        isTrendingSearchLoading: false,
        trendingSearch: []
    };
};

export const updateFilterTabType = (state = INITIAL_STATE, { activeFilterTab }) => {
    return {
        ...state,
        activeFilterTab
    };
};

export const updateSelectedSort = (state = INITIAL_STATE, { selectedSort }) => {
    return {
        ...state,
        selectedSort
    };
};

export const clearAllFilters = (state = INITIAL_STATE) => {
    clearItem(LOCALSTORAGE_CONSTANTS.PREV_APPLIED_FILTERS);
    return {
        ...state,
        selectedFilters: [],
        urlOptions: {},
        appliedFilters: [],
        selectedSuggestions: [],
        appliedSuggestions: [],
        selectedSort: INITIAL_STATE.selectedSort
    };
};

export const clearSelectedFilters = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        selectedFilters: data,
        selectedSuggestions: []
    };
};

export const updateAppliedFilters = (state = INITIAL_STATE) => {
    return {
        ...state,
        appliedFilters: [
            ...state.selectedFilters
        ]
    };
};

export const updateAppliedSuggestions = (state = INITIAL_STATE) => {
    return {
        ...state,
        appliedSuggestions: [
            ...state.selectedSuggestions
        ]
    };
};

export const updateUrlOptions = (state = INITIAL_STATE, { urlOptions }) => {
    return {
        ...state,
        urlOptions
    };
};

export const clearSuggestions = (state = INITIAL_STATE, { filterKey }) => {
    const updatedSuggestions = state.selectedSuggestions.filter(suggestion => suggestion.filterKey !== filterKey);
    return {
        ...state,
        selectedSuggestions: updatedSuggestions
    };
};

export const updateAllSubFilterOptions = (state = INITIAL_STATE, { filterKey, optionKey, isSelected }) => {
    return {
        ...state,
        selectedFilters: getUpdatedSubFilterOptions({ filterKey, optionKey, isSelected, selectedFilters: state.selectedFilters, allFilters: state.allFilters })
    };
};

export const updateCustomValue = (state = INITIAL_STATE, { key, value }) => {
    return {
        ...state,
        customFilterData: {
            [key]: value
        }
    };
};

export const setIsReturningUser = (state = INITIAL_STATE, { isReturningUser }) => {
    return { ...state, isReturningUser };
};

export const setNudgeGlow = (state = INITIAL_STATE, { showNudgeGlow }) => {
    return { ...state, showNudgeGlow };
};

export const setSearchResultTrackingData = (state = INITIAL_STATE, { data = {} }) => {
    return {
        ...state,
        searchResultTrackingData: data
    };
};

// Recommended Cars
const fetchRecommendedCarsInit = (state = INITIAL_STATE) => {
    return {
        ...state,
        isRecommendedCarsLoading: true,
        recommendedCars: []
    };
};

const fetchRecommendedCarsSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        isRecommendedCarsLoading: false,
        recommendedCars: data
    };
};

const fetchRecommendedCarsFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        isRecommendedCarsLoading: false,
        recommendedCars: []
    };
};

// Recently Viewed Cars
export const updateRecentlyViewedSSRStatus = (state = INITIAL_STATE, { isSSR }) => {
    return { ...state, isRecentlyViewedSSR: isSSR };
};

const fetchRecentlyViewedCarsInit = (state = INITIAL_STATE) => {
    return {
        ...state,
        isRecentlyViewedCarsLoading: true,
        recentlyViewedCars: []
    };
};

export  const fetchRecentlyViewedCarsSuccess = (state = INITIAL_STATE, { data, isRecentlyViewedSSR = false }) => {
    return {
        ...state,
        isRecentlyViewedCarsLoading: false,
        recentlyViewedCars: data,
        isRecentlyViewedSSR
    };
};

const fetchRecentlyViewedCarsFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        isRecentlyViewedCarsLoading: false,
        recentlyViewedCars: []
    };
};

// Recently Added Cars
const fetchRecentlyAddedCarsInit = (state = INITIAL_STATE) => {
    return {
        ...state,
        isRecentlyAddedCarsLoading: true,
        recentlyAddedCars: []
    };
};

export const fetchRecentlyAddedCarsSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        isRecentlyAddedCarsLoading: false,
        recentlyAddedCars: data
    };
};

const fetchRecentlyAddedCarsFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        isRecentlyAddedCarsLoading: false,
        recentlyAddedCars: []
    };
};

// Custom Recommended Cars
const fetchCustomRecommendedCarsInit = (state = INITIAL_STATE) => {
    return {
        ...state,
        isCustomRecommendedCarsLoading: true,
        customRecommendedCars: []
    };
};

const fetchCustomRecommendedCarsSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        isCustomRecommendedCarsLoading: false,
        customRecommendedCars: data
    };
};

const fetchCustomRecommendedCarsFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        isCustomRecommendedCarsLoading: false,
        customRecommendedCars: []
    };
};

const updateSavedAppliedFilters = (state = INITIAL_STATE, { data }) => {
    const SAVED_FILTER = {
        "key": "savedFilters",
        "displayText": "SAVED FILTERS",
        "filterType": "sf",
        "enabledForSeo": true,
        "priority": 3,
        "mainFilter": true,
        "uiType": "RADIOBOX",
        "options": [
        ]
    };
    SAVED_FILTER.options = data;
    return {
        ...state,
        savedFilters: data,
        allFilters: {...state.allFilters, savedFilters: SAVED_FILTER}
    };
};

export const updateSelectedFiltersListWithSavedFilters = (state = INITIAL_STATE, { data, savedSelectedFilter }) => {
    return {
        ...state,
        selectedFilters: [...data],
        appliedFilters: [],
        savedSelectedFilter
    };
};

export const updateNewFilterStatus = (state = INITIAL_STATE, { flag }) => {
    return {
        ...state,
        newFilterSelected: flag
    };
};

export const HANDLERS = {
    [Types.FETCH_FILTERS]: fetchFiltersInit,
    [Types.FETCH_FILTERS_SUCCESS]: fetchFiltersSuccess,
    [Types.FETCH_FILTERS_FAILURE]: fetchFiltersFailure,
    [Types.UPDATE_SSR_STATUS]: updateSSRStatus,
    [Types.UPDATE_FILTER_TIMESTAMP]: updateFilterStamp,
    [Types.UPDATE_SELECTED_ON_SCREEN_FILTER]: updateSelectedOnScreenFilter,
    [Types.UPDATE_FILTER_OPTION_DATA]: updateSelectedFiltersList,
    [Types.UPDATE_ALL_FILTERS_VISIBILITY]: updateAllFiltersVisibility,
    [Types.FETCH_LISTING_COUNT]: fetchListingCountInit,
    [Types.FETCH_LISTING_COUNT_SUCCESS]: fetchListingCountSuccess,
    [Types.FETCH_LISTING_COUNT_FAILURE]: fetchListingCountFailure,
    [Types.FETCH_TRENDING_SEARCH_INIT]: fetchTrendingSearchInit,
    [Types.FETCH_TRENDING_SEARCH_SUCCESS]: fetchTrendingSearchSuccess,
    [Types.FETCH_TRENDING_SEARCH_FAILURE]: fetchTrendingSearchFailure,
    [Types.UPDATE_FILTER_TAB_TYPE]: updateFilterTabType,
    [Types.UPDATE_SELECTED_SORT]: updateSelectedSort,
    [Types.CLEAR_ALL_FILTERS]: clearAllFilters,
    [Types.UPDATE_APPLIED_FILTERS]: updateAppliedFilters,
    [Types.UPDATE_URL_OPTIONS]: updateUrlOptions,
    [Types.UPDATE_SELECTED_SUGGESTIONS]: updateSelectedSuggestions,
    [Types.UPDATE_APPLIED_SUGGESTIONS]: updateAppliedSuggestions,
    [Types.CLEAR_SUGGESTIONS]: clearSuggestions,
    [Types.UPDATE_ALL_SUB_FILTER_OPTIONS]: updateAllSubFilterOptions,
    [Types.UPDATE_SSR_FILTERS_LIST]: updateSSRFiltersList,
    [Types.UPDATE_CUSTOM_VALUE]: updateCustomValue,
    [Types.CLEAR_SELECTED_FILTERS]: clearSelectedFilters,
    [Types.SET_IS_RETURNING_USER]: setIsReturningUser,
    [Types.SET_SEARCH_RESULT_TRACKING_DATA]: setSearchResultTrackingData,
    [Types.FETCH_RECOMMENDED_CARS_INIT]: fetchRecommendedCarsInit,
    [Types.FETCH_RECOMMENDED_CARS_SUCCESS]: fetchRecommendedCarsSuccess,
    [Types.FETCH_RECOMMENDED_CARS_FAILURE]: fetchRecommendedCarsFailure,
    [Types.FETCH_RECENTLY_VIEWED_CARS_INIT]: fetchRecentlyViewedCarsInit,
    [Types.FETCH_RECENTLY_VIEWED_CARS_SUCCESS]: fetchRecentlyViewedCarsSuccess,
    [Types.FETCH_RECENTLY_VIEWED_CARS_FAILURE]: fetchRecentlyViewedCarsFailure,
    [Types.FETCH_RECENTLY_ADDED_CARS_INIT]: fetchRecentlyAddedCarsInit,
    [Types.FETCH_RECENTLY_ADDED_CARS_SUCCESS]: fetchRecentlyAddedCarsSuccess,
    [Types.FETCH_RECENTLY_ADDED_CARS_FAILURE]: fetchRecentlyAddedCarsFailure,
    [Types.FETCH_CUSTOM_RECOMMENDED_CARS_INIT]: fetchCustomRecommendedCarsInit,
    [Types.FETCH_CUSTOM_RECOMMENDED_CARS_SUCCESS]: fetchCustomRecommendedCarsSuccess,
    [Types.FETCH_CUSTOM_RECOMMENDED_CARS_FAILURE]: fetchCustomRecommendedCarsFailure,
    [Types.LISTING_NUDGE_GLOW]: setNudgeGlow,
    [Types.UPDATE_RECENTLY_VIEWED_SSR_STATUS]: updateRecentlyViewedSSRStatus,
    [Types.UPDATE_FILTERS_APPLIED]: updateSavedAppliedFilters,
    [Types.UPDATE_SELECTED_FILTERS_WITH_SAVED_FILTERS]: updateSelectedFiltersListWithSavedFilters,
    [Types.UPDATE_NEW_FILTER_SELECTED]: updateNewFilterStatus
};

export default createReducer(INITIAL_STATE, HANDLERS);
