import { INITIAL_STATE as carEvaluation } from "./components/ae.mobile/car-evaluation-info/reducers";
import { INITIAL_STATE as loanEligibility } from "./components/ae.mobile/loan-eligibility-modal/reducers";

export const migrationsMobile = {
    1: (state) => {
        return {
            ...state,
            carEvaluation: {
                ...state.carEvaluation
            }
        };
    },
    3: (state) => {
        return {
            ...state,
            carEvaluation: {
                ...state.carEvaluation,
                inspectionType: carEvaluation.inspectionType,
                bookingDetail: {
                    ...state.bookingDetail
                }
            }
        };
    },
    4: (state) => {
        return {
            ...state,
            loanEligibility: {
                ...state.loanEligibility,
                maxCarEmi: loanEligibility.maxCarEmi,
                maxCarPrice: loanEligibility.maxCarPrice,
                carAvailable: loanEligibility.carAvailable
            }
        };
    }
};
