import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";

export const INITIAL_STATE = {
    npsQuestions: null,
    displayQuestions: [],
    rating: null,
    isLoading: false,
    isUpdating: false
};

const apiRequest = (state = INITIAL_STATE, { isSubmit }) => {
    return { ...state, isLoading: isSubmit ? false : true, isUpdating: isSubmit };
};
const apiResponse = (state = INITIAL_STATE) => {
    return { ...state, isLoading: false, isUpdating: false };
};
const setNpsQuestions = (state = INITIAL_STATE, { data }) => {
    return { ...state, npsQuestions: data, isLoading: false };
};
const setDisplayQuestions = (state = INITIAL_STATE, { data }) => {
    return { ...state, displayQuestions: data };
};
const setRating = (state = INITIAL_STATE, { data }) => {
    return { ...state, rating: data };
};
export const HANDLERS = {
    [Types.API_REQUEST]: apiRequest,
    [Types.API_RESPONSE]: apiResponse,
    [Types.SET_NPS_QUESTIONS]: setNpsQuestions,
    [Types.SET_DISPLAY_QUESTIONS]: setDisplayQuestions,
    [Types.SET_RATING]: setRating
};

export default createReducer(INITIAL_STATE, HANDLERS);
