import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    FETCH_SALESFORCE_DETAILS_INITIATED
    FETCH_SALESFORCE_DETAILS_SUCCESS
    FETCH_SALESFORCE_DETAILS_FAILURE

    UPDATE_DOCUMENT_INITIATED
    UPDATE_DOCUMENT_SUCCESS
    UPDATE_DOCUMENT_FAILURE

    SUBMIT_DOCUMENTS_DETAILS_SUCCESS
    SUBMIT_DOCUMENTS_DETAILS_FAILURE

    SUBMIT_FINANCE_DETAILS_SUCCESS
    SUBMIT_FINANCE_DETAILS_FAILURE
`,
    {
        prefix: "salesforceuploads/"
    }
);
