import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";

export const INITIAL_STATE = {
    isLoading: false,
    isError: false,
    isSSR: false
};

export const fetchLeadsSuccess = (state = INITIAL_STATE, {data}) => {
    return {
        ...state,
        isLoading: false,
        isError: false,
        ...data
    };
};

export const fetchLeadsFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        isLoading: false,
        isError: true
    };
};

export const HANDLERS = {
    [Types.FETCH_LEADS_SUCCESS]: fetchLeadsSuccess,
    [Types.FETCH_LEADS_FAILURE]: fetchLeadsFailure
};

export default createReducer(INITIAL_STATE, HANDLERS);

