import { createReducer } from "../../../utils/redux/create-reducer";
import { NUMBER } from "../../../constants/app-constants";
import saveCookie from "../../../utils/helpers/save-cookie";
import Types from "./types";

export const INITIAL_STATE = {
    selectedCars: [],
    compareData: {
        details: {},
        metadata: {}
    },
    config: null,
    totalCars: 0,
    isSSR: false,
    isLoading: false,
    error: null,
    isComparingLimitError: false,
    isComparingActive: false
};

export const setComparingActive = (state = INITIAL_STATE, { isComparingActive }) => {
    return {
        ...state,
        isComparingActive
    };
};

export const addCarToCompare = (state = INITIAL_STATE, { carDetails, config }) => {
    saveCookie("compareCarsBucket", JSON.stringify({selectedCars: [...state.selectedCars, carDetails], config}));
    return {
        ...state,
        selectedCars: [...state.selectedCars, carDetails],
        totalCars: state.totalCars + 1,
        isComparingActive: true,
        config
    };
};

export const removeCarCompare = (state = INITIAL_STATE, { appId }) => {
    const selectedCars = [...state.selectedCars.filter((car) => car.appointmentId !== appId)];
    const selectedCarsContent = {...state.compareData.details};
    delete selectedCarsContent[appId];
    saveCookie("compareCarsBucket", JSON.stringify({selectedCars, config: state.config}));
    return {
        ...state,
        selectedCars,
        isComparingLimitError: selectedCars.length >= NUMBER.THREE,
        compareData: {
            ...state.compareData,
            details: {
                ...selectedCarsContent
            }
        },
        totalCars: selectedCars.length
    };
};

export const fetchComparingData = (state = INITIAL_STATE) => {
    return {
        ...state,
        isLoading: true
    };
};

export const fetchComparingDataSuccess = (state = INITIAL_STATE, { data }) => {
    const { details, metadata, config } = data;
    return {
        ...state,
        selectedCars: Object.values(details).map(({ appointmentId, make, model, year, variant, mainImage }) => ({ appointmentId, make, model, year, variant, mainImage})),
        compareData: {
            details,
            metadata
        },
        config,
        totalCars: Object.values(details).length,
        isLoading: false
    };
};

export const fetchComparingDataFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        error,
        isLoading: false
    };
};

export const setCompareCarsLimitError = (state = INITIAL_STATE, { isComparingLimitError }) => {
    return {
        ...state,
        isComparingLimitError
    };
};

export const HANDLERS = {
    [Types.FETCH_COMPARING_DATA_SUCCESS]: fetchComparingDataSuccess,
    [Types.FETCH_COMPARING_DATA]: fetchComparingData,
    [Types.FETCH_COMPARING_DATA_FAILURE]: fetchComparingDataFailure,
    [Types.ADD_CAR_TO_COMPARE]: addCarToCompare,
    [Types.REMOVE_CAR_COMPARE]: removeCarCompare,
    [Types.SET_COMPARING_ACTIVE]: setComparingActive,
    [Types.SET_COMPARE_CAR_LIMIT_ERROR]: setCompareCarsLimitError
};

export default createReducer(INITIAL_STATE, HANDLERS);
