import { CrmService } from "../../../service";

const fetchTicketDetail = (ticketId, token) => () => {
    return new Promise((resolve, reject) => {
        CrmService.getTicket(ticketId, token)
            .then(response => resolve(response?.data))
            .catch(error => reject(error));
    });
};

const updateTicketDetail = (payload, ticketId, token) => () => {
    return new Promise((resolve, reject) => {
        CrmService.postTicket(payload, ticketId, token)
            .then(response => resolve(response?.data))
            .catch(error => reject(error));
    });
};

export {
    fetchTicketDetail,
    updateTicketDetail
};
