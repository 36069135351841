import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";

export const INITIAL_STATE = {
    quack: null,
    isReturnVisible: false,
    topTickets: null,
    allTickets: []
};

export const quack = (state = INITIAL_STATE) => {
    return { ...state, isLoading: true};
};

export const setReturnVisible = (state = INITIAL_STATE, { isReturnVisible }) => {
    return { ...state, isReturnVisible };
};

export const setTopTicket = (state = INITIAL_STATE, { topTickets }) => {
    return { ...state, topTickets };
};

export const setAllTicket = (state = INITIAL_STATE, { allTickets }) => {
    return { ...state, allTickets };
};
export const HANDLERS = {
    [Types.QUACK]: quack,
    [Types.SET_RETURN_VISIBLE]: setReturnVisible,
    [Types.SET_TOP_TICKET]: setTopTicket,
    [Types.SET_ALL_TICKETS]: setAllTicket
};

export default createReducer(INITIAL_STATE, HANDLERS);
