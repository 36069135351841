import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";

export const INITIAL_STATE = {
    isLoading: false,
    sellerTestimonials: [],
    buyerTestimonials: [],
    isError: false,
    isSSR: false
};

export const setSellerTestimonials = (state = INITIAL_STATE, {data}) => {
    return {
        ...state,
        sellerTestimonials: data
    };
};

export const HANDLERS = {
    [Types.SELLER_TESTIMONIALS_SUCCESS]: setSellerTestimonials
};

export default createReducer(INITIAL_STATE, HANDLERS);
