/* eslint-disable max-statements */
/* eslint-disable complexity */
import React, { useState, useEffect, useRef, memo } from "react";
import { useHistory, withRouter, useLocation } from "react-router-dom";
import PropTypes from "prop-types";

// Styles
import styles from "./styles.css";

// Icons
import UserIcon from "./images/user-icon.svg";
import BackIcon from "./images/icon-back.svg";
import SendIcon from "./images/send.svg";
import PickupIcon from "./images/icon-pickup.svg";

// Components
import InputTextarea from "../../shared/input-textarea";
import Login from "../login";
import withLogin from "../with-login";
import PageLoader from "../../shared/page-loader";
import ChatScreenTicketCard from "../chat-screen-ticket-card";
import PageLoadError from "../../shared/page-load-error";

// Constant
import { TICKET_TYPE } from "../../../constants/app-constants";
import { ROUTE_NAME, ROUTE_PATHS } from "../../../constants/routes-constants";

// Utilities
import { getPostBcCustomUrl } from "../../../utils/helpers/get-postbc-url";
import { formatDate } from "../../../utils/helpers/get-day-from-date";

const ChatSection = memo(({
    tid,
    secureToken,
    setComments,
    updateTicketDetailConnect
}) => {
    const [userMessageText, setUserMessageText] = useState("");
    const inputRef = useRef();

    const handleClick = () => {
        if (!userMessageText) return;
        const payload = {
            commentBody: userMessageText
        };
        updateTicketDetailConnect(payload, tid, secureToken).then(latestComment => {
            if (latestComment?.createdAt) {
                // Update comments
                setComments(oldComments => [...oldComments, latestComment]);

                // Restore to original height for inputbox
                inputRef.current.style.height = "42px";
            }
        });
        setUserMessageText("");
    };

    const handleUserMessageText = (event) => {
        setUserMessageText(event?.target?.value);
    };

    return (
        <div styleName={"styles.chatInput"}>
            <InputTextarea
                inputRef={inputRef}
                placeholder="Please type here"
                onChange={handleUserMessageText}
                value={userMessageText}
            />
            <span styleName={`styles.sendButton ${userMessageText ? "styles.active" : ""}`} onClick={handleClick}>
                <img src={SendIcon} alt="Send" />
            </span>
        </div>
    );
});

const ReturnRepairChatInfo = ({
    match: { params: { tid, appId } },
    fetchTicketDetailConnect,
    updateTicketDetailConnect,
    isUserDetailsFetched,
    secureToken,
    showLogin,
    setShowLogin,
    isLoggedIn
}) => {
    const history = useHistory();
    const [ticket, setTicket] = useState({});
    const [comments, setComments] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const { state: { pageSource = ROUTE_NAME.POST_BC } = {} } = useLocation();
    const {
        ticketId, open: ticketStatus, status: ticketCurrentStatus, tags = [],
        ticketType = "", createdAt, scheduledDate = "",
        address = "", city = "", externalId = ""
    } = ticket || {};

    const onLoginClose = () => setShowLogin(false);

    const handleBack = () => {
        let redirectionUrl;
        if (pageSource === ROUTE_NAME.POST_BC) {
            const { relativeURL: postBcUrlRelativeUrl } = getPostBcCustomUrl({ lid: appId });
            redirectionUrl = postBcUrlRelativeUrl;
        } else {
            redirectionUrl = `/${ROUTE_PATHS.viewAllTickets}/${externalId}/${appId}`;
        }
        history.push(redirectionUrl);
    };

    useEffect(() => {
        if (isLoggedIn) {
            setIsLoading(true);
            fetchTicketDetailConnect(tid, secureToken).then(ticketInfo => {
                const { ticketId: id } = ticketInfo || {};
                if (id) {
                    setTicket(ticketInfo);
                    setComments(ticketInfo?.comments);
                } else {
                    handleBack();
                }
            }).catch(() => {
                setIsError(true);
            }).finally(() => {
                setIsLoading(false);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn, tid]);

    useEffect(() => {
        if (isUserDetailsFetched && !isLoggedIn) {
            setShowLogin(true);
        } else {
            setShowLogin(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUserDetailsFetched, isLoggedIn]);

    const createMarkup = (comment = "") => {
        return { __html: comment.split("\n").join("</br>") };
    };

    if (isError) {
        return <PageLoadError onClose={handleBack} />;
    }
    return (
        <Login isVisible={showLogin} onSuccess={onLoginClose}>
            {(!isLoading && ticketId) ? (
                <div styleName={"styles.mainWrapper"}>
                    <div styleName={"styles.headWrapper"}>
                        <span styleName={"styles.backButton"} onClick={handleBack}>
                            <img src={BackIcon} alt="back" />
                        </span>
                        <h2>{ticketType === TICKET_TYPE.REPAIR ? "Repair" : "Return"} Request</h2>
                    </div>
                    <div styleName={"styles.requestTicketWrap"}>
                        <ChatScreenTicketCard
                            ticketId={ticketId}
                            ticketType={ticketType === TICKET_TYPE.REPAIR ? "Repair Request" : "Return Request"}
                            isTicketOpen={ticketStatus}
                            ticketCurrentStatus={ticketCurrentStatus}
                            createdDate={createdAt}
                        />
                    </div>
                    {(ticketType === TICKET_TYPE.RETURN && !!scheduledDate) && (
                        <div styleName={"styles.pickupScheduled"}>
                            <span styleName={"styles.iconWrap"}><img src={PickupIcon} alt="pickup scheduled" /></span>
                            <div>
                                <h3>Pick Up Scheduled : {formatDate(scheduledDate, "DATE_MONTH")}</h3>
                                <p>{address} - {city}</p>
                            </div>
                        </div>
                    )}
                    <div styleName={"styles.tabRow"}>
                        {tags.map((tag, index) => (
                            <div key={`${tag}_${index}`}>{tag}</div>
                        ))}
                    </div>
                    <div styleName="styles.chatWrap">
                        {comments.map((comment, index) => {
                            const { myComment: isUserComment, body } = comment || {};
                            return isUserComment ? (
                                <div key={index} styleName={"styles.myChat"}>
                                    <p dangerouslySetInnerHTML={createMarkup(body)} />
                                </div>
                            ) : (
                                <div key={index} styleName={"styles.theirChat"}>
                                    <span><img src={UserIcon} alt="user" /></span>
                                    <p dangerouslySetInnerHTML={createMarkup(body)} />
                                </div>
                            );
                        })}
                        <div styleName={"styles.noteText"}>
                            {ticketStatus ? (
                                <p>“We'll get back to you within 24 hours”</p>
                            ) : (
                                <p>“This ticked has been closed”</p>
                            )}
                        </div>
                        {ticketStatus && (
                            <ChatSection
                                tid={tid}
                                secureToken={secureToken}
                                setComments={setComments}
                                updateTicketDetailConnect={updateTicketDetailConnect}
                            />
                        )}
                    </div>
                </div>
            ) : (
                <PageLoader />
            )}
        </Login >
    );
};

ReturnRepairChatInfo.propTypes = {
    match: PropTypes.any,
    secureToken: PropTypes.string,
    showLogin: PropTypes.bool,
    setShowLogin: PropTypes.func,
    isLoggedIn: PropTypes.bool,
    isUserDetailsFetched: PropTypes.bool,
    fetchTicketDetailConnect: PropTypes.func,
    updateTicketDetailConnect: PropTypes.func
};

ChatSection.propTypes = {
    tid: PropTypes.string,
    secureToken: PropTypes.string,
    setComments: PropTypes.func,
    updateTicketDetailConnect: PropTypes.func
};

export default withRouter(withLogin(ReturnRepairChatInfo));
