import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
        FETCH_PAYMENT_STATUS_INIT
        FETCH_PAYMENT_STATUS_SUCCESS
        FETCH_PAYMENT_STATUS_FAILURE
`,
    {
        prefix: "noonPayment/"
    }
);
