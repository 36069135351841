import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";

export const INITIAL_STATE = {
    details: {
        userDetail: {
            employmentType: null,
            monthlyIncome: "",
            isLoanOrEmiPresent: null,
            totalLoanOrEmiAmount: "",
            isCreditCardPresent: null,
            totalCreditCardLimit: "",
            isPrequalified: null,
            phoneNumber: ""
        },
        quotesList: null,
        selectedQuote: null,
        status: null,
        errorResponse: null,
        appointmentId: null
    },
    backURL: "/",
    carCfFlow: null,
    isLoading: false,
    isCreating: false,
    isUpdating: false,

    isLoadingError: false,
    isCreateError: false,
    isUpdateError: false,
    error: null
};

export const setCarCfFlow = (state = INITIAL_STATE, { status }) => {
    return {
        ...state,
        carCfFlow: status
    };
};

export const getUserCFDetailsRequest = (state = INITIAL_STATE) => {
    return {
        ...state,
        isLoading: true,
        isLoadingError: false,
        error: null
    };
};

export const getUserCFDetailsSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        details: { ...state.details, ...data },
        isLoading: false,
        isLoadingError: false,
        error: null
    };
};

export const getUserCFDetailsFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        isLoading: false,
        isLoadingError: true,
        error
    };
};

export const createUserCFDetailsRequest = (state = INITIAL_STATE) => {
    return {
        ...state,
        isCreating: true,
        isCreatingError: false,
        error: null
    };
};

export const createUserCFDetailsSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        details: { ...state.details, ...data },
        isCreating: false,
        isCreatingError: false,
        error: null
    };
};

export const createUserCFDetailsFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        isCreating: false,
        isCreatingError: true,
        error
    };
};

export const updateUserCFDetailsRequest = (state = INITIAL_STATE) => {
    return {
        ...state,
        isUpdating: true,
        isUpdateError: false,
        error: null
    };
};

export const updateUserCFDetailsSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        details: { ...state.details, ...data },
        isUpdating: false,
        isUpdatingError: false,
        error: null
    };
};

export const updateUserCFDetailsFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        isUpdating: false,
        isUpdatingError: true,
        error
    };
};

export const setUserCfBackURL = (state = INITIAL_STATE, { backURL }) => {
    return {
        ...state,
        backURL
    };
};

export const HANDLERS = {
    [Types.GET_USER_CF_DETAILS_REQUEST]: getUserCFDetailsRequest,
    [Types.GET_USER_CF_DETAILS_SUCCESS]: getUserCFDetailsSuccess,
    [Types.GET_USER_CF_DETAILS_FAILURE]: getUserCFDetailsFailure,

    [Types.CREATE_USER_CF_DETAILS_REQUEST]: createUserCFDetailsRequest,
    [Types.CREATE_USER_CF_DETAILS_SUCCESS]: createUserCFDetailsSuccess,
    [Types.CREATE_USER_CF_DETAILS_FAILURE]: createUserCFDetailsFailure,

    [Types.UPDATE_USER_CF_DETAILS_REQUEST]: updateUserCFDetailsRequest,
    [Types.UPDATE_USER_CF_DETAILS_SUCCESS]: updateUserCFDetailsSuccess,
    [Types.UPDATE_USER_CF_DETAILS_FAILURE]: updateUserCFDetailsFailure,

    [Types.SET_USER_CF_BACK_URL]: setUserCfBackURL,
    [Types.SET_CAR_CF_FLOW]: setCarCfFlow
};

export default createReducer(INITIAL_STATE, HANDLERS);
